import { type HTMLAttributes, type PropsWithChildren, type ReactElement } from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import styles from '../assets/scss/ToggleButtonGroup.module.scss'

interface ToggleButtonGroupProps
  extends PropsWithChildren,
  HTMLAttributes<HTMLDivElement> {
  selectedBtnIndex: number
  onClick: () => void
}

const ToggleButtonGroup = ({
  children,
  selectedBtnIndex,
  className,
  onClick
}: ToggleButtonGroupProps): ReactElement => (
  <div
    className={classNames(styles['toggle-btn-group'], className)}
    data-testid="toggle-btn-group"
  >
    {Array.isArray(children) &&
      children.map((child, index) => (
        <Button
          key={index}
          type="button"
          variant="light"
          className={styles['toggle-btn']}
          onClick={onClick}
          disabled={selectedBtnIndex === index}
        >
          {child}
        </Button>
      ))}
  </div>
)
export default ToggleButtonGroup
