import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useBasket from '../hooks/useBasket'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import MaConfirm from '../../common/components/MaConfirm'
import type { BasketLineItem, SpendCategory } from '@amici/myamici-api-client'
import BasketGroupEditSpendCategoriesModal from './BasketGroupEditSpendCategoriesModal'

const updatedSpendCategories = (
  basketLineItem: BasketLineItem,
  spendCategories: SpendCategory[]
): SpendCategory[] => {
  const lineItemSpendCategories =
    basketLineItem.line_item.spend_categories ?? []
  return spendCategories.filter(
    updatedSpendCategory =>
      !lineItemSpendCategories.some(
        existingSpendCategory =>
          existingSpendCategory.field_id === updatedSpendCategory.field_id &&
          existingSpendCategory.id === updatedSpendCategory.id
      )
  )
}
const updatedBasketLineItem = (
  basketLineItem: BasketLineItem,
  spendCategories: SpendCategory[]
): BasketLineItem => {
  const changedSpendCategories = updatedSpendCategories(
    basketLineItem,
    spendCategories
  )
  const lineItemSpendCategories =
    basketLineItem.line_item.spend_categories ?? []
  const unchangedSpendCategories = lineItemSpendCategories.filter(
    existingSpendCategory =>
      !changedSpendCategories.some(
        changedSpendCategory =>
          changedSpendCategory.field_id === existingSpendCategory.field_id
      )
  )
  return {
    ...basketLineItem,
    line_item: {
      ...basketLineItem.line_item,
      issues: [],
      spend_categories: [
        ...unchangedSpendCategories,
        ...changedSpendCategories
      ]
    },
    product_stock: {
      ...basketLineItem.product_stock,
      id: '0'
    }
  }
}

interface BasketActionMenuProps {
  items: BasketLineItem[]
  classes?: string
}

export function BasketActionMenu ({
  items,
  classes
}: Readonly<BasketActionMenuProps>): ReactElement {
  const { t } = useTranslation()
  const { updateAll, removeAll, isClearing, isUpdating } = useBasket()
  const { showToastMessage } = useToastNotification()
  const [showClearBasket, setShowClearBasket] = useState(false)
  const [showGroupEditSpendCategories, setShowGroupEditSpendCategories] =
    useState(false)

  const handleClearBasket = async (): Promise<void> => {
    try {
      await removeAll()
      showToastMessage('dark', t('basket.clear_all.success'))
    } catch (e) {
      showToastMessage('danger', t('basket.clear_all.error'))
    }
    setShowClearBasket(false)
  }

  const closeGroupEditSpendCategories = (): void => {
    setShowGroupEditSpendCategories(false)
  }

  const handleGroupEditSpendCategories = async (
    spendCategories: SpendCategory[]
  ): Promise<void> => {
    const updatedBasketLineItems = items
      .filter(
        basketLineItem =>
          updatedSpendCategories(basketLineItem, spendCategories).length > 0
      )
      .map(basketLineItem =>
        updatedBasketLineItem(basketLineItem, spendCategories)
      )
    closeGroupEditSpendCategories()
    try {
      await updateAll(updatedBasketLineItems)
    } catch {
      showToastMessage('danger', t('basket.group_edit_spend_categories.error'))
    }
  }

  return (
    <>
      <MaActionMenu className={classes} aria-label={t('basket.action.menu')}>
        <MaActionMenuItem
          disabled={items.length === 0}
          onClick={() => {
            setShowGroupEditSpendCategories(true)
          }}
        >
          {t('basket.action.menu.group_edit_spend_categories')}
        </MaActionMenuItem>
        <MaActionMenuItem
          onClick={() => {
            setShowClearBasket(true)
          }}
        >
          {t('basket.action.menu.clear_basket')}
        </MaActionMenuItem>
      </MaActionMenu>
      <BasketGroupEditSpendCategoriesModal
        show={showGroupEditSpendCategories}
        onSave={handleGroupEditSpendCategories}
        onCancel={closeGroupEditSpendCategories}
        disabled={isUpdating}
      />
      <MaConfirm
        variant="warning"
        disabled={isClearing}
        title={t('common.title.warning')}
        closeLabel={t('common.button.labels.cancel')}
        confirmLabel={t('common.button.labels.ok')}
        show={showClearBasket}
        onConfirm={() => {
          void handleClearBasket()
        }}
        onClose={() => {
          setShowClearBasket(false)
        }}
      >
        <p>{t('basket.clear.basket.modal.warning')}</p>
        <p>{t('basket.clear.basket.modal.continue')}</p>
      </MaConfirm>
    </>
  )
}
