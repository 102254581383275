import { type ReactElement } from 'react'
import { type OrderRequest } from '@amici/myamici-api-client'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import OrderRequestHistoryEventEntry from './OrderRequestHistoryEventEntry'

function OrderRequestHistory ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  const { historyEntries, isLoading } = useOrderRequestHistory(orderRequest.id)

  return isLoading
    ? (
    <LoadingSpinner />
      )
    : (
    <>
      {historyEntries.map(entry => (
        <OrderRequestHistoryEventEntry entry={entry} key={entry.id} />
      ))}
    </>
      )
}

export default OrderRequestHistory
