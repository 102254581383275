import { type Notification } from '@amici/myamici-api-client'
import useSWR, { useSWRConfig } from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import ReactGA from 'react-ga4'

interface UseNotificationsHook {
  isLoading: boolean
  notifications: Notification[]
  readNotifications: Notification[]
  unreadNotifications: Notification[]
  removeNotification: (uuid: string) => Promise<void>
  markAsRead: (uuid: string) => Promise<void>
  markAllAsRead: () => Promise<void>
}

function useNotifications (): UseNotificationsHook {
  const { notificationsApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()
  const { mutate } = useSWRConfig()
  const {
    data,
    isLoading,
    mutate: mutateNotifications
  } = useSWR(
    activeAccount?.accountId
      ? ['notifications', activeAccount?.accountId]
      : null,
    async () =>
      await fetcher(notificationsApi.getPage, {
        size: 0,
        direction: 'desc',
        accountId: activeAccount?.accountId
      })
  )

  const notifications: Notification[] = data?.content || []

  const readPredicate = (notification: Notification): boolean =>
    Boolean(notification.read)
  const unreadPredicate = (notification: Notification): boolean =>
    !readPredicate(notification)

  const readNotifications = notifications.filter(readPredicate)
  const unreadNotifications = notifications.filter(unreadPredicate)

  const updateUnreadCounter = async (count: number): Promise<void> => {
    await mutate(
      ['unread-notifications-count', activeAccount?.accountId],
      count
    )
  }

  const removeNotification = async (uuid: string): Promise<void> => {
    ReactGA.event('action_item', {
      item_list_id: 'notifications',
      item_id: uuid,
      action: 'delete'
    })

    try {
      await notificationsApi.remove({ uuid })

      const updatedNotifications = notifications.filter(
        notification => notification.uuid !== uuid
      )

      await mutateNotifications()
      await updateUnreadCounter(
        updatedNotifications.filter(unreadPredicate).length
      )
    } catch (error) {
      console.warn(`Couldn't delete notification ${uuid}`)
    }
  }

  const markAsRead = async (uuid: string): Promise<void> => {
    ReactGA.event('action_item', {
      item_list_id: 'notifications',
      item_id: uuid,
      action: 'mark_as_read'
    })

    try {
      await notificationsApi.markAsRead({ uuid })

      const updatedNotifications = notifications.map(notification =>
        notification.uuid === uuid
          ? { ...notification, read: true }
          : notification
      )

      await mutateNotifications()
      await updateUnreadCounter(
        updatedNotifications.filter(unreadPredicate).length
      )
    } catch (error) {
      console.warn(`Couldn't mark as read notification ${uuid}`)
    }
  }

  const markAllAsRead = async (): Promise<void> => {
    ReactGA.event('action_item_list', {
      item_list_id: 'notifications',
      action: 'mark_all_as_read'
    })

    const requests = unreadNotifications.map(
      async ({ uuid }) =>
        await notificationsApi.markAsRead({ uuid: uuid ?? '' })
    )

    try {
      await Promise.all(requests)
      await mutateNotifications()
      await updateUnreadCounter(0)
    } catch (error) {
      console.warn("Couldn't mark all notifications as read")
    }
  }

  return {
    isLoading,
    notifications,
    readNotifications,
    unreadNotifications,
    removeNotification,
    markAsRead,
    markAllAsRead
  }
}

export default useNotifications
