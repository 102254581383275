import useSWRMutation from 'swr/mutation'
import {
  type DeliveryReceipt,
  type OrdersApiReceiveLineItemsRequest
} from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface UseReceiveLineItemsHook {
  isMutating: boolean
  error?: Error
  submit: (
    orderId: string | undefined,
    items: DeliveryReceipt[],
  ) => Promise<void>
}

function useReceiveLineItems (): UseReceiveLineItemsHook {
  const {
    ordersApi: { receiveLineItems },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { trigger, isMutating, error } = useSWRMutation(
    accountId ? ['receive-line-items', accountId] : null,
    async (_, { arg }: { arg: OrdersApiReceiveLineItemsRequest }) => {
      await fetcher(receiveLineItems, arg)
    },
    { populateCache: false, revalidate: false }
  )

  const submit = async (
    orderId: string | undefined,
    items: DeliveryReceipt[]
  ): Promise<void> => {
    if (!orderId || !accountId) {
      return
    }

    await trigger({
      orderId,
      accountId,
      deliveryReceiptsContent: { content: items }
    })
  }

  return {
    isMutating,
    error,
    submit
  }
}

export default useReceiveLineItems
