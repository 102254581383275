import { type ChangeEvent, type FormEvent, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type FormProps } from 'react-bootstrap'
import { OrderRequestSearchType } from '../types/order-request-search-type'
import SearchForm from '../../purchasing/components/SearchForm'

interface OrderSearchFormProps extends FormProps {
  search: string
  type: OrderRequestSearchType
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  onTypeChange?: (searchType: OrderRequestSearchType) => void
}

function OrderRequestSearchForm ({
  search,
  type,
  onSubmit,
  onSearchChange,
  onTypeChange,
  ...props
}: Readonly<OrderSearchFormProps>): ReactElement {
  const { t } = useTranslation()

  const options: Array<{ label: string, value: OrderRequestSearchType }> = [
    {
      label: t('order_requests.search.type.my_requests'),
      value: OrderRequestSearchType.MyOrderRequests
    },
    {
      label: t('order_requests.search.type.all_requests'),
      value: OrderRequestSearchType.AllOrderRequests
    }
  ]

  return (
    <SearchForm<OrderRequestSearchType>
      search={search}
      type={type}
      typeOptions={options}
      placeholder={t('order_requests.search.placeholder')}
      onSubmit={onSubmit}
      onTypeChange={onTypeChange}
      onSearchChange={onSearchChange}
      data-testid="order-request-search-form"
      {...props}
    />
  )
}

export default OrderRequestSearchForm
