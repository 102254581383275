import {
  type ChangeEvent,
  type FormEvent,
  type ReactElement,
  useEffect,
  useRef,
  useState
} from 'react'
import { Button, Form, type FormProps, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import classNames from 'classnames'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import styles from '../assets/scss/SearchForm.module.scss'

interface SearchFormProps<SearchType> extends FormProps {
  search: string
  type?: SearchType
  typeOptions?: Array<{ label: string, value: SearchType }>
  placeholder?: string
  invalidFeedback?: string | null
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  onTypeChange?: (searchType: SearchType) => void
}

function SearchForm<SearchType> ({
  search,
  type,
  typeOptions,
  invalidFeedback = null,
  placeholder = '',
  className,
  onSubmit,
  onSearchChange,
  onTypeChange,
  ...props
}: Readonly<SearchFormProps<SearchType>>): ReactElement {
  const { t } = useTranslation()
  const isInvalid = !!invalidFeedback
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false)

  useEffect(() => {
    inputRef.current?.focus()
  }, [type])

  const handleSearchTypeChange = (searchType: string): void => {
    if (onTypeChange) {
      onTypeChange(searchType as SearchType)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      className={classNames(
        styles.form,
        { [styles.active]: isTypeSelectOpen },
        className
      )}
      {...props}
    >
      <InputGroup>
        {typeOptions && typeOptions?.length > 0 && (
          <MaSelect
            aria-label={t('common.search.type')}
            className={styles.type}
            value={type as string}
            onValueChange={handleSearchTypeChange}
            onOpenChange={setIsTypeSelectOpen}
            onCloseAutoFocus={e => {
              e.preventDefault()
            }}
          >
            {typeOptions.map(typeOption => (
              <MaSelectItem
                key={typeOption.value as string}
                value={typeOption.value as string}
              >
                {typeOption.label}
              </MaSelectItem>
            ))}
          </MaSelect>
        )}

        <Form.Control
          aria-label={t('common.search.label')}
          className={styles['query-input']}
          value={search}
          onChange={onSearchChange}
          placeholder={placeholder}
          isInvalid={isInvalid}
          ref={inputRef}
        />
        <Button
          tabIndex={-1}
          className={styles['query-btn']}
          variant="light"
          type="submit"
          disabled={isInvalid}
        >
          <BsSearch size={24} />
        </Button>

        <Form.Control.Feedback type="invalid">
          {invalidFeedback}
        </Form.Control.Feedback>
      </InputGroup>
    </Form>
  )
}

export default SearchForm
