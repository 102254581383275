import { type ReactElement } from 'react'
import { type OrderResource } from '@amici/myamici-api-client'
import { Link } from 'react-router-dom'

function OrderReference ({
  order,
  link
}: Readonly<{
  order: OrderResource
  link?: string
}>): ReactElement {
  return link
    ? (
    <Link to={link} target="_blank">
      {order.reference}
    </Link>
      )
    : (
    <>{order.reference}</>
      )
}

function PurchaseOrderReference ({
  order,
  link
}: Readonly<{
  order: OrderResource
  link?: string
}>): ReactElement {
  return (
    <>
      {order.consolidation_reference
        ? (
        <>
          {order.consolidation_reference} (
          <OrderReference order={order} link={link} />)
        </>
          )
        : (
        <OrderReference order={order} link={link} />
          )}
    </>
  )
}
export default PurchaseOrderReference
