import { useCallback } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import decodeHTML from '../../common/utils/decode-html'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import {
  type ProductNote,
  type ProductNoteResource,
  type ProductsApiSaveProductNoteRequest
} from '@amici/myamici-api-client'
import ReactGA from 'react-ga4'

interface UseProductNotesHook {
  data: ProductNoteResource | undefined
  update: (note: ProductNote) => Promise<void>
  isLoading: boolean
  isMutating: boolean
  isValidating: boolean
  hasData: boolean
  loadingError: any
  mutationError: any
}

function useProductNotes (productId?: string): UseProductNotesHook {
  const { activeAccount } = useAccounts()
  const { productsApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const {
    data,
    isLoading,
    isValidating,
    error: loadingError
  } = useSWR(
    productId && accountId ? ['product-note', productId, accountId] : null,
    async () =>
      await fetcher(productsApi.getProductNote, {
        productId: productId ?? '',
        accountId
      }).then(
        (data: ProductNoteResource): ProductNoteResource => ({
          ...data,
          note: decodeHTML(data.note ?? ''),
          internal_part_number: decodeHTML(data.internal_part_number ?? '')
        })
      )
  )

  const {
    trigger,
    isMutating,
    error: mutationError
  } = useSWRMutation(
    productId && accountId ? ['product-note', productId, accountId] : null,
    async (_, { arg }: { arg: ProductsApiSaveProductNoteRequest }) =>
      await fetcher(productsApi.saveProductNote, arg).then(
        (data: ProductNoteResource): ProductNoteResource => ({
          ...data,
          note: decodeHTML(data.note ?? ''),
          internal_part_number: decodeHTML(data.internal_part_number ?? '')
        })
      ),
    { revalidate: false }
  )

  const update = useCallback(
    async (productNote: ProductNote): Promise<void> => {
      if (!productId || !accountId) {
        return
      }

      try {
        await trigger(
          {
            productId,
            accountId,
            productNote
          },
          {
            optimisticData: (current: any): any => ({
              ...current,
              note: productNote.note,
              internal_part_number: productNote.internal_part_number
            })
          }
        )
      } catch (error) {
        console.warn('Product note update failed')
      }

      ReactGA.event('submit_form', {
        form_id: 'product_notes',
        product_id: productId
      })
    },
    [accountId, productId, trigger]
  )

  const hasData: boolean = !!(data?.internal_part_number || data?.note)

  return {
    data,
    update,
    isLoading,
    isMutating,
    isValidating,
    hasData,
    loadingError,
    mutationError
  }
}

export default useProductNotes
