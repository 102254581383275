import { useCallback, useEffect, useRef, type ReactElement } from 'react'
import {
  type SpendCategoryField,
  type SpendCategory,
  SpendCategoryStatusEnum
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import { type ValidateResult, useForm, type Control } from 'react-hook-form'
import { type SpendCategoryNode } from '../types/spend-category-node'
import useSpendCategories from '../hooks/useSpendCategories'
import MaConfirm from '../../common/components/MaConfirm'
import styles from '../assets/scss/SpendCategories.module.scss'
import FormControlTextArea from '../../common/components/FormControlTextArea'

export interface SpendCategoryFormModalProps {
  parentNode?: SpendCategoryNode | null
  node?: SpendCategoryNode
  fieldId: string
  activeGroup?: SpendCategoryField
  onError: (message: string) => void
  onClose: () => void
}

function SpendCategoryFormModal ({
  parentNode,
  node,
  fieldId,
  activeGroup,
  onError,
  onClose
}: SpendCategoryFormModalProps): ReactElement {
  const { t } = useTranslation()
  const {
    addNew,
    handleUpdate,
    isUpdating,
    isAdding,
    isLoading,
    isValidating
  } = useSpendCategories()
  const {
    register,
    watch,
    control,
    reset,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      externalRef: '',
      summary: ''
    },
    values: {
      name: node?.name ?? '',
      code: node?.code ?? '',
      externalRef: node?.external_ref ?? '',
      summary: node?.description ?? ''
    }
  })

  const mode: string = node ? 'edit' : 'add'

  const show = node || parentNode !== undefined
  const isBusy = isLoading || isValidating || isAdding || isUpdating

  const handleAddNew = async (): Promise<void> => {
    const values = getValues()

    const newSpendCategory: SpendCategory = {
      id: 0,
      name: values.name.trim(),
      parent_id: parentNode === null ? null : parentNode?.id,
      field_id: fieldId,
      code: values.code.trim(),
      external_ref: values.externalRef.trim(),
      description: values.summary.trim(),
      status: parentNode?.status ?? SpendCategoryStatusEnum.ACTIVE
    }

    try {
      await addNew(newSpendCategory)
    } catch {
      onError(t('spend_categories.error.add_new'))
    } finally {
      handleClose()
    }
  }

  const handleEdit = async (): Promise<void> => {
    const values = getValues()

    const spendCategory = activeGroup?.spend_categories.find(
      spendCategory => spendCategory.id === node?.id
    ) as SpendCategory

    const updatedSpendCategory = {
      ...spendCategory,
      name: values.name.trim(),
      code: values.code.trim(),
      external_ref: values.externalRef.trim(),
      description: values.summary.trim()
    }

    try {
      await handleUpdate(updatedSpendCategory)
    } catch {
      onError(t('spend_categories.error.update'))
    } finally {
      handleClose()
    }
  }

  const validateName = useCallback(
    (name: string | undefined): ValidateResult => {
      const nameIsUsed = activeGroup?.spend_categories
        .filter(spendCategory => {
          if (mode === 'add') {
            return (
              spendCategory.parent_id ===
              (parentNode === null ? null : parentNode?.id)
            )
          }

          return (
            spendCategory.parent_id === node?.parent_id &&
            spendCategory.id !== node?.id
          )
        })
        .some(spendCategory => spendCategory.name.trim() === name?.trim())

      if (nameIsUsed) {
        return t('validation.error.spend_category.name.non_unique')
      }

      if (!name?.trim()) {
        return t('validation.error.spend_category.name.required')
      }

      return true
    },
    [
      activeGroup?.spend_categories,
      mode,
      node?.id,
      node?.parent_id,
      parentNode,
      t
    ]
  )

  const handleConfirm = async (): Promise<void> => {
    if (!isValid) {
      return
    }

    if (mode === 'add') {
      void handleAddNew()
    }

    if (mode === 'edit') {
      void handleEdit()
    }
  }

  const handleClose = (): void => {
    onClose()
    reset({
      name: '',
      code: '',
      externalRef: '',
      summary: ''
    })
  }

  const nameInputRef = useRef<HTMLInputElement | null>(null)

  const { ref: nameRef, ...registerName } = register('name', {
    validate: validateName,
    maxLength: 100
  })

  useEffect(() => {
    show && nameInputRef.current?.focus()
  }, [show])

  return (
    <MaConfirm
      size="lg"
      show={show}
      disabled={isBusy || !isValid}
      title={t(`spend_categories.modal.title.${mode}`)}
      closeLabel={t('common.button.labels.cancel')}
      confirmLabel={t('common.button.labels.save')}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <Form>
        <Row className={styles['spend-category-row']}>
          <Form.Group as={Col} sm={4} controlId="description">
            <Form.Label className="required">
              {t('spend_categories.label.description')}
            </Form.Label>
            <Form.Control
              {...registerName}
              ref={(el: HTMLInputElement | null) => {
                nameRef(el)
                nameInputRef.current = el
              }}
              isInvalid={!!errors?.name}
              maxLength={100}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm={4} controlId="code">
            <Form.Label className="required">
              {t('spend_categories.label.code')}
            </Form.Label>
            <Form.Control
              {...register('code', {
                validate: value =>
                  !!value.trim() ||
                  t('validation.error.spend_category.code.required'),
                maxLength: 100
              })}
              isInvalid={!!errors?.code}
              maxLength={100}
            />
            <Form.Control.Feedback type="invalid">
              {errors.code?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm={4} controlId="external-ref">
            <Form.Label>{t('spend_categories.label.external_ref')}</Form.Label>
            <Form.Control {...register('externalRef')} maxLength={50} />
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </Row>

        <Row>
          <FormControlTextArea
            label={t('spend_categories.label.summary')}
            value={watch('summary')}
            name={'summary'}
            required={false}
            maxLength={500}
            rows={5}
            control={control as unknown as Control}
          />
        </Row>
      </Form>
    </MaConfirm>
  )
}

export default SpendCategoryFormModal
