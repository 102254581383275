import { type PropsWithChildren, type ReactElement } from 'react'
import {
  type OrderLineItemResource,
  type OrderResource
} from '@amici/myamici-api-client'
import { Trans, useTranslation } from 'react-i18next'
import { type CardProps, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { format } from 'date-fns'
import useOrderItems from '../hooks/useOrderItems'
import MaCard from '../../common/components/MaCard'
import styles from '../assets/scss/OrderCard.module.scss'
import ReceiveOrderLineItem from './ReceiveOrderLineItem'
import useIsMobile from '../../common/hooks/useIsMobile'
import LoadingSpinner from '../../common/components/LoadingSpinner'

interface OrderCardProps extends CardProps, PropsWithChildren {
  order: OrderResource
  handleReceiveItemsModalOpen: (
    order: OrderResource,
    items: OrderLineItemResource[],
    showLineItemModal: boolean,
  ) => void
}

function ReceiveOrderCard ({
  order,
  className,
  handleReceiveItemsModalOpen,
  ...props
}: Readonly<OrderCardProps>): ReactElement {
  const { t } = useTranslation()
  const { data, isLoading } = useOrderItems(order.id)
  const orderViewUrl = `/purchasing/orders/${order.id}`
  const items = data?.content ?? []

  const lineItems: OrderLineItemResource[] = items
    .filter(item => item.quantity !== item.quantity_received)
    .concat(items.filter(item => item.quantity === item.quantity_received))

  const isMobile = useIsMobile()

  const showReceiveItemModal = (item: OrderLineItemResource): void => {
    handleReceiveItemsModalOpen(order, [item], true)
  }

  const receiveOutstandingBtn = (): ReactElement => {
    if (order.status !== 'complete') {
      return (
        <Button
          type="submit"
          variant="primary"
          className={classNames('rounded', { 'w-100': isMobile })}
          onClick={() => {
            handleReceiveItemsModalOpen(order, items, false)
          }}
        >
          {t('receive_orders.item.card.receive_outstanding')}
        </Button>
      )
    }

    return (
      <p
        className={classNames(styles['order-received'], {
          [styles.mobile]: isMobile
        })}
      >
        {t('receive_orders.item.card.order_received')}
      </p>
    )
  }

  return (
    <>
      <MaCard
        className={classNames(styles['order-card'], className)}
        {...props}
      >
        <MaCard.Body>
          <div className={styles.content}>
            <div className={styles['left-col']}>
              <p>
                {order.confidential && (
                  <b>{t('order.card.confidential_order')} </b>
                )}
                <Link
                  to={orderViewUrl}
                  className={styles.title}
                  data-testid="order-reference"
                >
                  {order.reference}
                </Link>
              </p>
              <div>{order.supplier?.name && <p>{order.supplier.name}</p>}</div>
            </div>
            <div className={styles['right-col']}>
              {!isMobile && receiveOutstandingBtn()}
              <p
                className={classNames(styles['ordered-by'], {
                  [styles.mobile]: isMobile
                })}
              >
                <Trans
                  i18nKey="order.card.ordered_by"
                  values={{
                    name: order.ordered_by?.name,
                    date: format(
                      new Date(order.order_date ?? 0),
                      'do MMMM yyyy'
                    )
                  }}
                  components={{ bold: <b /> }}
                />
              </p>
              {isMobile && receiveOutstandingBtn()}
            </div>
          </div>

          <div className={classNames(styles['line-items'])}>
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}
            {(!lineItems || lineItems.length === 0) && (
              <p>{t('order.items.none')}</p>
            )}
            {lineItems.map((lineItem, index) => (
              <div key={lineItem.id}>
                <hr />
                <ReceiveOrderLineItem
                  item={lineItem}
                  showReceiveItemModal={showReceiveItemModal}
                />
              </div>
            ))}
          </div>
        </MaCard.Body>
      </MaCard>
    </>
  )
}

export default ReceiveOrderCard
