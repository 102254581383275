import { type AccountResource } from '@amici/myamici-api-client'
import { User } from 'oidc-client-ts'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import useSWR, { type KeyedMutator } from 'swr'
import UserAccount from '../models/UserAccount'
import { useSessionStorage } from 'usehooks-ts'
import useSharedSession from './useSharedSession'
import useAccountSwitcher from './useAccountSwitcher'
import useApi from './useApi'

const SESSION_STORAGE_ACCOUNTS = 'accounts'
const SESSION_STORAGE_ACTIVE_ACCOUNT_ID = 'activeAccountId'

interface UserAccountsHook {
  accounts?: UserAccount[] | null
  activeAccount: UserAccount | null
  accountProfile?: AccountResource
  refreshAccountProfile: KeyedMutator<AccountResource>
  isRefreshingAccountProfile: boolean
}

function useAccounts (): UserAccountsHook {
  const { user } = useAuth()
  const { userSessionData } = useSharedSession(user?.profile?.sid)
  const accountSwitcherUrl = useAccountSwitcher()

  const [accounts, setAccounts] = useSessionStorage<UserAccount[] | null>(
    SESSION_STORAGE_ACCOUNTS,
    null
  )
  const [activeAccountId, setActiveAccountId] = useSessionStorage<
  string | null
  >(SESSION_STORAGE_ACTIVE_ACCOUNT_ID, null)
  const activeAccount =
    accounts?.find(
      (account: UserAccount) => account.accountId === activeAccountId
    ) ?? null

  const sessionAccountId = userSessionData?.CURRENT_ACCOUNT

  const {
    accountsApi: { get: getAccountProfile },
    fetcher
  } = useApi()
  const {
    data: accountProfile,
    mutate: refreshAccountProfile,
    isValidating: isRefreshingAccountProfile
  } = useSWR(
    sessionAccountId ? ['account-profile', sessionAccountId] : null,
    async () =>
      await fetcher(getAccountProfile, { accountId: sessionAccountId })
  )

  useEffect(() => {
    if (!(user instanceof User)) {
      return
    }

    let accounts
    if (typeof user.profile.MA3Accounts === 'string') {
      accounts = JSON.parse(user.profile.MA3Accounts)
    } else if (
      Array.isArray(user.profile.MA3Accounts) &&
      user.profile.MA3Accounts.length > 0
    ) {
      accounts = user.profile.MA3Accounts[0]
    } else if (typeof user.profile.MA3Accounts === 'object') {
      accounts = user.profile.MA3Accounts
    } else {
      return
    }

    const userAccounts: UserAccount[] = accounts.Accounts.map(
      (account: any) =>
        new UserAccount(
          account.Id.toString() as string,
          account.Username as string,
          account.ClientName as string,
          account.Modules as string[],
          account.Permissions as string[]
        )
    )

    setAccounts(userAccounts)

    if (sessionAccountId) {
      setActiveAccountId(sessionAccountId)
      return
    }

    // Redirect to MA3 account switcher
    if (userSessionData && !userSessionData.CURRENT_ACCOUNT) {
      location.href = accountSwitcherUrl
    }
  }, [
    userSessionData,
    sessionAccountId,
    user,
    setAccounts,
    setActiveAccountId,
    accountSwitcherUrl
  ])

  return {
    accounts,
    activeAccount,
    accountProfile,
    refreshAccountProfile,
    isRefreshingAccountProfile
  }
}

export default useAccounts
