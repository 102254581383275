import { type ChangeEvent, type ReactElement, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useProductHazards from '../hooks/useProductHazards'
import styles from '../assets/scss/ProductDetailsTabHazards.module.scss'
import { Button, Form, Table } from 'react-bootstrap'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useIsMobile from '../../common/hooks/useIsMobile'
import classNames from 'classnames'
import {
  ProductHazardClientStatusEnum,
  ProductHazardProductStatusEnum,
  ProductRestrictionClientStatusEnum,
  ProductRestrictionProductStatusEnum,
  type ProductResource
} from '@amici/myamici-api-client'
import { BsPencilFill } from 'react-icons/bs'
import { UserPermission } from '../../common/types/user-permission'
import useAccounts from '../../common/hooks/useAccounts'
import MaConfirm from '../../common/components/MaConfirm'
import MaWarning from '../../common/components/MaWarning'

function HazardsAndRestrictionsSections ({
  product
}: Readonly<{
  product: ProductResource
}>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const isMobile = useIsMobile()
  const {
    data,
    submit,
    isLoading,
    isMutating,
    hasHazards,
    hasPotentialHazards,
    hasRestrictions,
    hasPotentialRestrictions
  } = useProductHazards(product.id)

  const [selectedHazardIds, setSelectedHazardIds] = useState<string[]>([])
  const [selectedRestrictionIds, setSelectedRestrictionIds] = useState<
  string[]
  >([])

  const initSelectedHazardsAndRestrictions = (): void => {
    setSelectedHazardIds(
      data?.hazards
        ?.filter(
          hazard =>
            hazard.client_status !== ProductHazardClientStatusEnum.DISPUTED &&
            hazard.product_status !== ProductHazardProductStatusEnum.NONE
        )
        .map(hazard => hazard.id) ?? []
    )
    setSelectedRestrictionIds(
      data?.restrictions
        ?.filter(
          restriction =>
            restriction.client_status !==
              ProductRestrictionClientStatusEnum.DISPUTED &&
            restriction.product_status !==
              ProductRestrictionProductStatusEnum.NONE
        )
        .map(restriction => restriction.id) ?? []
    )
  }

  const potentialHazardsOrRestrictionsSelected =
    data?.hazards && data?.restrictions
      ? data?.hazards?.filter(
        hazard =>
          selectedHazardIds.some(id => id === hazard.id) &&
            hazard.client_status === ProductHazardClientStatusEnum.NONE &&
            hazard.product_status ===
              ProductHazardProductStatusEnum.THIRD_PARTY
      ).length > 0 ||
        data?.restrictions?.filter(
          restriction =>
            selectedRestrictionIds.some(id => id === restriction.id) &&
            restriction.client_status ===
              ProductRestrictionClientStatusEnum.NONE &&
            restriction.product_status ===
              ProductRestrictionProductStatusEnum.THIRD_PARTY
        ).length > 0
      : false

  const [showEditForm, setShowEditForm] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const canEdit = activeAccount?.permissions.includes(
    UserPermission.IsHazardApprover
  )
  const canSubmit = canEdit && !isLoading && !isMutating

  const handleSubmit = async (): Promise<void> => {
    setSubmissionError(false)

    const updatedHazards = data?.hazards
      ?.map(hazard => {
        if (selectedHazardIds.some(id => id === hazard.id)) {
          hazard.client_status = ProductHazardClientStatusEnum.ACCEPTED
        } else if (
          hazard.product_status === ProductHazardProductStatusEnum.THIRD_PARTY
        ) {
          hazard.client_status = ProductHazardClientStatusEnum.DISPUTED
        } else {
          hazard.client_status = ProductHazardClientStatusEnum.NONE
        }
        return hazard
      })
      .filter(
        hazard =>
          hazard.product_status !== ProductHazardProductStatusEnum.NONE ||
          hazard.client_status !== ProductHazardClientStatusEnum.NONE
      )

    const updatedRestrictions = data?.restrictions
      ?.map(restriction => {
        if (selectedRestrictionIds.some(id => id === restriction.id)) {
          restriction.client_status =
            ProductRestrictionClientStatusEnum.ACCEPTED
        } else if (
          restriction.product_status ===
          ProductHazardProductStatusEnum.THIRD_PARTY
        ) {
          restriction.client_status =
            ProductRestrictionClientStatusEnum.DISPUTED
        } else {
          restriction.client_status = ProductRestrictionClientStatusEnum.NONE
        }
        return restriction
      })
      .filter(
        restriction =>
          restriction.product_status !==
            ProductRestrictionProductStatusEnum.NONE ||
          restriction.client_status !== ProductRestrictionClientStatusEnum.NONE
      )

    try {
      await submit({
        coshh: data?.coshh,
        licence: data?.licence,
        note: data?.note,
        cas_hazard: {
          ...data?.cas_hazard,
          cas_number: data?.cas_hazard?.cas_number ?? ''
        },
        hazards: updatedHazards,
        restrictions: updatedRestrictions
      })

      setShowEditForm(false)
    } catch (error) {
      setSubmissionError(true)
    }
  }

  const handleCancel = (): void => {
    setShowEditForm(false)
    setSubmissionError(false)
  }

  const handleCheck = (event: ChangeEvent<HTMLInputElement>): void => {
    const [type, updatedId] = event.target.id.split('-')

    if (event.target.checked) {
      if (type === 'hazard') {
        setSelectedHazardIds([...selectedHazardIds, updatedId])
      } else {
        setSelectedRestrictionIds([...selectedRestrictionIds, updatedId])
      }
    } else {
      if (type === 'hazard') {
        setSelectedHazardIds(selectedHazardIds.filter(id => id !== updatedId))
      } else {
        setSelectedRestrictionIds(
          selectedRestrictionIds.filter(id => id !== updatedId)
        )
      }
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <section className={styles['hazards-and-restrictions']}>
      <div className={styles.actions}>
        {canEdit && (
          <Button
            className={styles['edit-btn']}
            aria-label="edit"
            variant="light"
            onClick={() => {
              initSelectedHazardsAndRestrictions()
              setShowEditForm(true)
            }}
          >
            <BsPencilFill size={16} />
          </Button>
        )}
      </div>

      {data?.cas_hazard?.has_cas_hazard && (
        <p className="fw-bold mb-4">
          {t('product.details.tabs.hazards.content.cas_hazard', {
            cas_number: data?.cas_hazard?.cas_number
          })}
        </p>
      )}

      {!hasHazards && !hasRestrictions && !data?.cas_hazard?.has_cas_hazard && (
        <p className="text-center">
          {t('product.details.tabs.hazards.content.no_hazards_or_restrictions')}
        </p>
      )}

      {hasHazards && (
        <Table className="mb-4">
          <thead>
            <tr>
              <th>{t('product.details.tabs.hazards.content.status')}</th>
              <th>
                {t('product.details.tabs.hazards.content.hazard_warnings')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.hazards?.map(
              hazard =>
                hazard.client_status !==
                  ProductHazardClientStatusEnum.DISPUTED &&
                hazard.product_status !==
                  ProductHazardProductStatusEnum.NONE && (
                  <tr key={`hazard-${hazard.id}`}>
                    <td
                      className={classNames(styles.status, {
                        [styles.mobile]: isMobile
                      })}
                    >
                      {t(
                        `product.details.tabs.hazards.content.status.${
                          hazard.client_status ===
                            ProductHazardClientStatusEnum.ACCEPTED ||
                          hazard.product_status ===
                            ProductHazardProductStatusEnum.PRODUCT
                            ? 'hazard'
                            : 'potential_hazard'
                        }`
                      )}
                    </td>
                    <td className={styles.warning}>{hazard.name}</td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      )}

      {hasRestrictions && (
        <Table>
          <thead>
            <tr>
              <th>{t('product.details.tabs.hazards.content.status')}</th>
              <th>
                {t('product.details.tabs.hazards.content.restriction_warnings')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.restrictions?.map(
              restriction =>
                restriction.client_status !==
                  ProductRestrictionClientStatusEnum.DISPUTED &&
                restriction.product_status !==
                  ProductRestrictionProductStatusEnum.NONE && (
                  <tr key={`restriction-${restriction.id}`}>
                    <td
                      className={classNames(styles.status, {
                        [styles.mobile]: isMobile
                      })}
                    >
                      {t(
                        `product.details.tabs.hazards.content.status.${
                          restriction.client_status ===
                            ProductRestrictionClientStatusEnum.ACCEPTED ||
                          restriction.product_status ===
                            ProductRestrictionProductStatusEnum.PRODUCT
                            ? 'restriction'
                            : 'potential_restriction'
                        }`
                      )}
                    </td>
                    <td className={styles.warning}>{restriction.name}</td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      )}

      <MaConfirm
        size="lg"
        disabled={!canSubmit}
        show={showEditForm}
        title={
          <Trans
            i18nKey="product.details.tabs.hazards.form.hazards.title"
            values={{
              name: product.description
            }}
            components={{ bold: <b /> }}
          />
        }
        closeLabel={t('common.button.labels.cancel')}
        confirmLabel={t('common.button.labels.save')}
        onClose={handleCancel}
        onConfirm={() => {
          if (potentialHazardsOrRestrictionsSelected) {
            setShowConfirm(true)
            return
          }
          void handleSubmit()
        }}
        className={styles['edit-hazards']}
      >
        {(hasPotentialHazards || hasPotentialRestrictions) && (
          <MaWarning
            message={t(
              'product.details.tabs.hazards.form.potential_hazards_warning'
            )}
          />
        )}

        <div className={styles.tables}>
          <Table>
            <thead>
              <tr>
                <th />
                <th>{t('product.details.tabs.hazards.content.status')}</th>
                <th>{t('product.details.tabs.hazards.form.heading.hazard')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.hazards?.map(hazard => (
                <tr
                  key={`hazard-row-${hazard.id}`}
                  className={classNames({
                    [styles.potential]:
                      hazard.product_status ===
                        ProductHazardProductStatusEnum.THIRD_PARTY &&
                      hazard.client_status ===
                        ProductHazardClientStatusEnum.NONE
                  })}
                >
                  <td className={styles['checkbox-cell']}>
                    <Form.Check
                      id={`hazard-${hazard.id}`}
                      checked={selectedHazardIds.some(id => hazard.id === id)}
                      onChange={handleCheck}
                      className={styles.checkbox}
                    />
                  </td>
                  <td
                    className={classNames(styles['status-cell'], {
                      [styles.mobile]: isMobile
                    })}
                  >
                    {hazard.client_status ===
                      ProductHazardClientStatusEnum.ACCEPTED &&
                      t('product.details.tabs.hazards.content.status.hazard')}
                    {hazard.client_status ===
                      ProductHazardClientStatusEnum.DISPUTED &&
                      t('product.details.tabs.hazards.content.status.disputed')}
                    {hazard.client_status ===
                      ProductHazardClientStatusEnum.NONE &&
                      hazard.product_status ===
                        ProductHazardProductStatusEnum.NONE &&
                      t('product.details.tabs.hazards.content.status.none')}
                    {hazard.client_status ===
                      ProductHazardClientStatusEnum.NONE &&
                      hazard.product_status ===
                        ProductHazardProductStatusEnum.THIRD_PARTY &&
                      t(
                        'product.details.tabs.hazards.content.status.potential_hazard'
                      )}
                    {hazard.client_status ===
                      ProductHazardClientStatusEnum.NONE &&
                      hazard.product_status ===
                        ProductHazardProductStatusEnum.PRODUCT &&
                      t('product.details.tabs.hazards.content.status.hazard')}
                  </td>
                  <td>{hazard.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table>
            <thead>
              <tr>
                <th />
                <th>{t('product.details.tabs.hazards.content.status')}</th>
                <th>
                  {t('product.details.tabs.hazards.form.heading.restriction')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.restrictions?.map(restriction => (
                <tr
                  key={`restriction-row-${restriction.id}`}
                  className={classNames({
                    [styles.potential]:
                      restriction.product_status ===
                        ProductRestrictionProductStatusEnum.THIRD_PARTY &&
                      restriction.client_status ===
                        ProductRestrictionClientStatusEnum.NONE
                  })}
                >
                  <td className={styles['checkbox-cell']}>
                    <Form.Check
                      id={`restriction-${restriction.id}`}
                      checked={selectedRestrictionIds.some(
                        id => restriction.id === id
                      )}
                      onChange={handleCheck}
                      className={styles.checkbox}
                    />
                  </td>
                  <td
                    className={classNames(styles['status-cell'], {
                      [styles.mobile]: isMobile
                    })}
                  >
                    {restriction.client_status ===
                      ProductRestrictionClientStatusEnum.ACCEPTED &&
                      t(
                        'product.details.tabs.hazards.content.status.restriction'
                      )}
                    {restriction.client_status ===
                      ProductRestrictionClientStatusEnum.DISPUTED &&
                      t('product.details.tabs.hazards.content.status.disputed')}
                    {restriction.client_status ===
                      ProductRestrictionClientStatusEnum.NONE &&
                      restriction.product_status ===
                        ProductRestrictionProductStatusEnum.NONE &&
                      t('product.details.tabs.hazards.content.status.none')}
                    {restriction.client_status ===
                      ProductRestrictionClientStatusEnum.NONE &&
                      restriction.product_status ===
                        ProductRestrictionProductStatusEnum.THIRD_PARTY &&
                      t(
                        'product.details.tabs.hazards.content.status.potential_restriction'
                      )}
                    {restriction.client_status ===
                      ProductRestrictionClientStatusEnum.NONE &&
                      restriction.product_status ===
                        ProductRestrictionProductStatusEnum.PRODUCT &&
                      t(
                        'product.details.tabs.hazards.content.status.restriction'
                      )}
                  </td>
                  <td>{restriction.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {submissionError && (
          <p className="text-center small text-danger m-0">
            {t('product.details.tabs.hazards.form.submission_error')}
          </p>
        )}

        <MaConfirm
          size={isMobile ? 'sm' : undefined}
          variant={'question'}
          show={showConfirm}
          title={t('product.details.tabs.hazards.form.confirmation.title')}
          closeLabel={t('common.button.labels.cancel')}
          confirmLabel={t('common.button.labels.ok')}
          onConfirm={() => {
            void handleSubmit()
            setShowConfirm(false)
          }}
          onClose={() => {
            setShowConfirm(false)
          }}
          className={styles.confirmation}
        >
          <p className="small">
            {t('product.details.tabs.hazards.form.potential_hazards_warning')}
          </p>
          <p className="small">
            {t('product.details.tabs.hazards.form.are_you_sure')}
          </p>
        </MaConfirm>
      </MaConfirm>
    </section>
  )
}

export default HazardsAndRestrictionsSections
