import { type ReactElement, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsEnvelopeCheck } from 'react-icons/bs'
import ReactGA from 'react-ga4'
import useNotifications from '../hooks/useNotifications'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaActionIcon from '../../common/components/MaActionIcon'
import NotificationList from './NotificationList'
import styles from '../assets/scss/Notifications.module.scss'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'

enum FilterValue {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read',
}

function Notifications (): ReactElement {
  const { t } = useTranslation()
  const {
    isLoading,
    notifications,
    readNotifications,
    unreadNotifications,
    markAllAsRead
  } = useNotifications()
  const [filter, setFilter] = useState<FilterValue>(FilterValue.ALL)

  const handleFilterChange = (value: string): void => {
    ReactGA.event('filter_item_list', {
      item_list_id: 'notifications',
      filter_field: 'read',
      filter_value: value
    })
    setFilter(value as FilterValue)
  }

  const filterCount = {
    [FilterValue.ALL]: notifications?.length ?? 0,
    [FilterValue.UNREAD]: unreadNotifications?.length ?? 0,
    [FilterValue.READ]: readNotifications?.length ?? 0
  }

  return (
    <Container fluid="auto" className="h-100">
      <div className={styles.controls}>
        {notifications.length > 0 && (
          <>
            <MaSelect value={filter} onValueChange={handleFilterChange}>
              {Object.values(FilterValue).map(value => (
                <MaSelectItem key={value} value={value}>
                  {t(`notifications.filter.${value}`)}{' '}
                  {`(${filterCount[value]})`}
                </MaSelectItem>
              ))}
            </MaSelect>

            <MaActionIcon
              title={t('notifications.action.mark_all_as_read')}
              data-testid="btn-mark-all-read"
              onClick={() => {
                if (unreadNotifications.length > 0) {
                  void markAllAsRead()
                }
              }}
            >
              <BsEnvelopeCheck size={24} />
            </MaActionIcon>
          </>
        )}
      </div>

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <div className={styles.notifications} data-testid="page-content">
          <NotificationList
            totalCount={notifications?.length ?? 0}
            notifications={
              {
                [FilterValue.ALL]: notifications,
                [FilterValue.UNREAD]: unreadNotifications,
                [FilterValue.READ]: readNotifications
              }[filter]
            }
          />
        </div>
      )}
    </Container>
  )
}

export default Notifications
