import { type ReactElement } from 'react'
import { Button, type ButtonProps } from 'react-bootstrap'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useFavourites from '../hooks/useFavourites'
import styles from '../assets/scss/FavouriteButton.module.scss'

interface FavouriteButtonProps extends ButtonProps {
  productId: string
  scale?: number
}

function FavouriteButton ({
  productId,
  scale,
  ...props
}: Readonly<FavouriteButtonProps>): ReactElement {
  const { t } = useTranslation()
  const { setFavourite, allFavouriteProducts } = useFavourites()
  const isFavourite: boolean = !!allFavouriteProducts.find(
    p => p.id === productId
  )

  return (
    <Button
      className={classNames(
        styles['favourite-btn'],
        { [styles.checked]: isFavourite },
        props.className
      )}
      variant="light"
      onClick={() => {
        void setFavourite(productId, !isFavourite)
      }}
      aria-label={t('product.card.favourite')}
    >
      {isFavourite
        ? (
        <BsStarFill size={scale ?? 16} />
          )
        : (
        <BsStar size={scale ?? 16} />
          )}
    </Button>
  )
}

export default FavouriteButton
