import { type ReactElement } from 'react'
import { type Task } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import { useEnv } from '../../common/hooks/useEnv'
import getDaysHoursDiff from '../../common/utils/get-days-hours-diff'
import MaCard from '../../common/components/MaCard'
import styles from '../assets/scss/TaskCard.module.scss'

function TaskCard ({ task }: Readonly<{ task: Task }>): ReactElement {
  const { t } = useTranslation()
  const env = useEnv()

  const { days: createdDaysAgo, hours: createdHoursAgo } = getDaysHoursDiff(
    task.created_date
  )

  const referenceUrl = `${
    env.REACT_APP_MA3_BASE_URL as string
  }/Order/OrderView.aspx?OrderId=${task.record_id ?? ''}`

  const ReferenceLink = (): ReactElement | null =>
    task.reference && task.record_id
      ? (
      <a href={referenceUrl} target="_blank" rel="noreferrer">
        {task.reference}
      </a>
        )
      : null

  return (
    <MaCard className={styles['task-card']}>
      <MaCard.Header className={styles.header}>
        {t(`task.card.title.${task.type}`)}
      </MaCard.Header>

      <MaCard.Body>
        <MaCard.Text>
          {t(`task.card.text.${task.type}`, {
            created_by: task.created_by?.name,
            reference: task.reference,
            ...task.values
          })}
          <ReferenceLink />
          {task.values?.po_value &&
            t('task.card.price', { po_value: task.values.po_value })}
          {task.values?.supplier_name &&
            t('task.card.supplier', { supplier: task.values.supplier_name })}
        </MaCard.Text>
      </MaCard.Body>

      <MaCard.Footer className={styles.footer}>
        {createdDaysAgo
          ? t('task.card.created.days', { count: createdDaysAgo })
          : t('task.card.created.hours', { count: createdHoursAgo })}
      </MaCard.Footer>
    </MaCard>
  )
}

export default TaskCard
