import {
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode
} from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { type OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'
import { type OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger'
import classNames from 'classnames'
import styles from '../assets/scss/MaTooltip.module.scss'

function MaTooltip ({
  text,
  placement = 'top',
  trigger = 'click',
  className,
  children
}: PropsWithChildren &
HTMLAttributes<HTMLSpanElement> & {
  text: ReactNode
  placement?: 'top' | 'right' | 'bottom' | 'left'
  trigger?: OverlayTriggerType | OverlayTriggerType[]
}): ReactElement {
  const Tooltip = ({
    placement: _placement,
    arrowProps: _arrowProps,
    show: _show,
    popper: _popper,
    hasDoneInitialMeasure: _hasDoneInitialMeasure,
    ...props
  }: OverlayInjectedProps): ReactElement => {
    return (
      <div
        {...props}
        className={classNames(styles.text)}
        data-testid="ma-tooltip-text"
      >
        {text}
      </div>
    )
  }

  return (
    <OverlayTrigger
      overlay={Tooltip}
      placement={placement}
      trigger={trigger}
      rootClose
    >
      {({ ref, ...triggerHandler }) => (
        <span
          ref={ref}
          {...triggerHandler}
          className={classNames(styles['ma-tooltip'], className)}
          data-testid="ma-tooltip"
        >
          {children}
        </span>
      )}
    </OverlayTrigger>
  )
}

export default MaTooltip
