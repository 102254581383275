import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'
import {
  ProductHazardClientStatusEnum,
  ProductHazardProductStatusEnum,
  type ProductHazards,
  type ProductHazardsResource,
  ProductRestrictionClientStatusEnum,
  ProductRestrictionProductStatusEnum,
  type ProductsApiSaveHazardsRequest
} from '@amici/myamici-api-client'
import decodeHTML from '../../common/utils/decode-html'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface UseProductHazardsHook {
  data?: ProductHazardsResource
  isLoading: boolean
  isMutating: boolean
  loadingError: any
  mutationError: any
  hasData: boolean
  hasHazards?: boolean
  hasPotentialHazards?: boolean
  hasRestrictions?: boolean
  hasPotentialRestrictions?: boolean
  submit: (productHazards: ProductHazards) => Promise<void>
}

function decodeDataFields (
  data: ProductHazardsResource
): ProductHazardsResource {
  return {
    ...data,
    ...(data.coshh && {
      coshh: {
        ...data.coshh,
        reference: decodeHTML(data.coshh?.reference ?? '')
      }
    }),
    licence: {
      ...data.licence,
      has_licence: !!data.licence?.has_licence,
      reference: decodeHTML(data.licence?.reference ?? '')
    },
    ...(data.note && { note: decodeHTML(data.note ?? '') })
  }
}

function useProductHazards (productId: string): UseProductHazardsHook {
  const {
    productsApi: { getHazards, saveHazards },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const {
    data,
    isLoading,
    error: loadingError
  }: {
    data?: ProductHazardsResource
    isLoading: boolean
    error: any
  } = useSWRImmutable(
    productId && accountId ? ['product-hazards', productId, accountId] : null,
    async () =>
      await fetcher(getHazards, { productId, accountId }).then(
        decodeDataFields
      )
  )

  const {
    trigger,
    isMutating,
    error: mutationError
  } = useSWRMutation(
    productId && accountId ? ['product-hazards', productId, accountId] : null,
    async (_, { arg }: { arg: ProductsApiSaveHazardsRequest }) =>
      await fetcher(saveHazards, arg).then(decodeDataFields),
    { populateCache: true, revalidate: false }
  )

  const submit = async (productHazards: ProductHazards): Promise<void> => {
    if (!productId || !accountId) {
      return
    }

    await trigger({
      productId,
      accountId,
      productHazards
    })
  }

  const hasHazards = data?.hazards?.some(
    hazard =>
      hazard.client_status !== ProductHazardClientStatusEnum.DISPUTED &&
      hazard.product_status !== ProductHazardProductStatusEnum.NONE
  )
  const hasPotentialHazards = data?.hazards?.some(
    hazard =>
      hazard.client_status === ProductHazardClientStatusEnum.NONE &&
      hazard.product_status === ProductHazardProductStatusEnum.THIRD_PARTY
  )
  const hasRestrictions = data?.restrictions?.some(
    restriction =>
      restriction.client_status !==
        ProductRestrictionClientStatusEnum.DISPUTED &&
      restriction.product_status !== ProductRestrictionProductStatusEnum.NONE
  )
  const hasPotentialRestrictions = data?.restrictions?.some(
    restriction =>
      restriction.client_status === ProductRestrictionClientStatusEnum.NONE &&
      restriction.product_status ===
        ProductRestrictionProductStatusEnum.THIRD_PARTY
  )

  const hasData: boolean = !!(
    data?.coshh?.has_coshh ||
    data?.licence?.has_licence ||
    data?.note ||
    data?.cas_hazard?.has_cas_hazard ||
    hasHazards ||
    hasRestrictions
  )

  return {
    data,
    submit,
    isLoading,
    isMutating,
    loadingError,
    mutationError,
    hasData,
    hasHazards,
    hasPotentialHazards,
    hasRestrictions,
    hasPotentialRestrictions
  }
}

export default useProductHazards
