import { type ReactElement } from 'react'
import { type FacetFilters } from '../types/facet-filters'
import { BsX } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import styles from '../assets/scss/AppliedFilters.module.scss'

interface AppliedFiltersProps {
  filters: FacetFilters
  categoryNameFormatter: (category: string) => string
  filterNameFormatters?: Record<
  string,
  (category: string, name: string) => string
  >
  onFilterChange: (category: string, value: string, enabled: boolean) => void
  onFiltersClear: () => void
}

function AppliedFilters ({
  filters,
  categoryNameFormatter,
  filterNameFormatters,
  onFilterChange,
  onFiltersClear
}: Readonly<AppliedFiltersProps>): ReactElement | null {
  const { t } = useTranslation()

  const allValues = [...filters.values()].flatMap(v => [...v])
  const duplicateValues = new Set(
    allValues.filter((v, i) => allValues.includes(v, i + 1))
  )

  const sortedFilters = [...filters.entries()]
    .flatMap(([category, values]) =>
      [...values].map(value => [category, value])
    )
    .sort(([_, a], [__, b]) => (a === b ? 0 : a > b ? 1 : -1))

  if (sortedFilters.length < 1) {
    return null
  }

  return (
    <div className={styles['applied-filters']}>
      <div className={styles.header}>
        {t('common.search.filters.selected')}
        <span
          className={styles['clear-all']}
          onClick={onFiltersClear}
          role="button"
        >
          {t('common.button.labels.clear_all')}
        </span>
      </div>
      <ul className={styles.list}>
        {sortedFilters.map(([category, value]) => (
          <li key={`${category}-${value}`} className={styles['filter-item']}>
            {filterNameFormatters?.[category]
              ? filterNameFormatters[category](category, value)
              : value}
            {duplicateValues.has(value) &&
              ` (${categoryNameFormatter(category)})`}
            <span
              className={styles['remove-btn']}
              onClick={() => {
                onFilterChange(category, value, false)
              }}
              role="button"
            >
              <BsX size={24} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AppliedFilters
