import {
  type ChangeEvent,
  type FormEvent,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { addMonths, format } from 'date-fns'
import { type DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { type OrderRequests } from '@amici/myamici-api-client'
import { OrderRequestSearchType } from '../types/order-request-search-type'
import useOrderRequests from './useOrderRequests'
import useOrderRequestsPageState, {
  OrderRequestsPageAction
} from './useOrderRequestsPageState'
import { SortDirection } from '../../common/types/sort-direction'
import {
  OrderRequestSortBy,
  type OrderRequestSortOption
} from '../types/order-request-sort-option'
import type { FacetFilters } from '../../purchasing/types/facet-filters'
import filtersQueryStringToMap from '../../purchasing/utils/filters-query-string-to-map'
import filtersMapToParam from '../../purchasing/utils/filters-map-to-param'
import useOrderRequestFacets from './useOrderRequestFacets'
import { useTranslation } from 'react-i18next'
import filtersCount from '../../purchasing/utils/filters-count'

export interface UseOrderRequestsPageHook {
  data?: OrderRequests
  isLoading: boolean
  dateRange?: DateRange
  term: string
  isNewSearch: boolean
  searchType: OrderRequestSearchType
  sort: OrderRequestSortOption
  filters: FacetFilters
  filtersUpdated: boolean
  initialFacets: Record<string, Record<string, number>>
  availableFacets: Record<string, Record<string, number>>
  emptyFacets: boolean
  showOffcanvasFilters: boolean
  appliedFiltersCount: number
  handlePageChange: (page: number) => void
  handleDateRangeChange: (range: DateRange | undefined) => void
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSearchTypeChange: (searchType: OrderRequestSearchType) => void
  handleSortingChange: (value: OrderRequestSortOption) => void
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleOpenOffcanvasFilters: () => void
  handleCloseOffcanvasFilters: () => void
  handleApplyFilters: () => void
  handleFilterUpdate: (
    category: string,
    value: string,
    enabled: boolean,
  ) => void
  handleClearFilters: () => void
  filterNameFormatters: Record<
  string,
  (category: string, name: string) => string
  >
}

function useOrderRequestsPage (): UseOrderRequestsPageHook {
  const [queryParams, setQueryParams] = useSearchParams()
  const { t } = useTranslation()

  const term = queryParams.get('term') ?? ''
  const searchType =
    (queryParams.get('searchType') as OrderRequestSearchType) ??
    OrderRequestSearchType.MyOrderRequests
  const page = parseInt(queryParams.get('page') ?? '1', 10)
  const sort =
    (queryParams.get('sort') as OrderRequestSortBy) ??
    OrderRequestSortBy.CREATED_DATE
  const direction =
    (queryParams.get('direction') as SortDirection) ?? SortDirection.DESC
  const dateFrom = queryParams.get('dateFrom') ?? undefined
  const dateTo = queryParams.get('dateTo') ?? undefined
  const dateRange: DateRange | undefined =
    dateFrom && dateTo
      ? {
          from: new Date(dateFrom),
          to: new Date(dateTo)
        }
      : undefined

  const filters = queryParams.get('filters') ?? ''
  const filtersMap: FacetFilters = useMemo(
    () => filtersQueryStringToMap(filters),
    [filters]
  )
  const appliedFiltersCount = useMemo(
    () => filtersCount(filtersMap),
    [filtersMap]
  )

  const { pageState, dispatch } = useOrderRequestsPageState({
    term,
    filters: filtersMap,
    filtersUpdated: false,
    showOffcanvasFilters: false
  })

  const { initialFacets, availableFacets } = useOrderRequestFacets(
    term,
    searchType,
    filtersMapToParam(pageState.filters),
    dateRange
  )

  const emptyFacets: boolean = Object.values(initialFacets ?? {})
    .map(category => Object.values(category))
    .every(values => values.length < 1)

  const filterNameFormatters = useMemo(
    () => ({
      confidential: (_: string, name: string): string =>
        t(`order_requests.search.filter.confidential.${name.toLowerCase()}`)
    }),
    [t]
  )

  const lastUpdatedCategory = useRef<
  | {
    name: string
    value: Record<string, number>
  }
  | undefined
  >()

  const patchedAvailableFacets = {
    ...availableFacets,
    ...(pageState.filters.size > 0 &&
      lastUpdatedCategory.current && {
      [lastUpdatedCategory.current.name]: lastUpdatedCategory.current.value
    })
  }

  useEffect(() => {
    clearLastUpdatedCategory()

    dispatch({
      type: OrderRequestsPageAction.PATCH_STATE,
      value: {
        term,
        filters: filtersMap
      }
    })

    if (dateFrom === undefined && dateTo === undefined) {
      const defaultDateFrom = format(addMonths(new Date(), -3), 'yyyy-MM-dd')
      const defaultDateTo = format(new Date(), 'yyyy-MM-dd')

      setQueryParams(
        params => {
          params.set('dateFrom', defaultDateFrom)
          params.set('dateTo', defaultDateTo)

          return params
        },
        { replace: true }
      )
    }
  }, [dateFrom, dateTo, dispatch, filtersMap, setQueryParams, term])

  // TODO: pass search params to the fetching hook
  const { data, isLoading } = useOrderRequests({
    term,
    page,
    sort,
    direction,
    dateFrom,
    dateTo,
    searchType,
    facetFilter: filtersMapToParam(filtersMap)
  })

  const clearLastUpdatedCategory = (): void => {
    lastUpdatedCategory.current = undefined
  }

  const isNewSearch: boolean = pageState.term !== term

  const handleDateRangeChange = (range: DateRange | undefined): void => {
    setQueryParams(params => {
      if (range?.from) {
        params.set('dateFrom', format(range.from, 'yyyy-MM-dd'))
      } else {
        params.set('dateFrom', '')
      }

      if (range?.to) {
        params.set('dateTo', format(range.to, 'yyyy-MM-dd'))
      } else if (range?.from) {
        params.set('dateTo', format(range.from, 'yyyy-MM-dd'))
      } else {
        params.set('dateTo', '')
      }

      params.set('page', '1')
      return params
    })

    dispatch({ type: OrderRequestsPageAction.CLEAR_FILTERS })
  }

  const handlePageChange = (page: number): void => {
    setQueryParams(params => {
      params.set('page', page.toString())

      return params
    })
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: OrderRequestsPageAction.UPDATE_SEARCH_TERM,
      value: e.target.value
    })
  }

  const handleSearchTypeChange = (searchType: OrderRequestSearchType): void => {
    setQueryParams(params => {
      params.set('searchType', searchType)
      params.set('page', '1')

      params.delete('filters')

      return params
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!isNewSearch) {
      return
    }

    setQueryParams(params => {
      if (pageState.term) {
        params.set('term', pageState.term)
      } else {
        params.delete('term')
      }

      return params
    })
  }

  const handleOpenOffcanvasFilters = (): void => {
    dispatch({ type: OrderRequestsPageAction.OPEN_OFFCANVAS_FILTERS })
  }

  const handleCloseOffcanvasFilters = (): void => {
    dispatch({ type: OrderRequestsPageAction.CLOSE_OFFCANVAS_FILTERS })
  }

  const handleSortingChange = (value: OrderRequestSortOption): void => {
    setQueryParams(params => {
      params.set('sort', value.field)
      params.set('direction', value.direction)

      return params
    })
  }

  const handleFilterUpdate = (
    category: string,
    value: string,
    enabled: boolean
  ): void => {
    const updatedFilters: FacetFilters = new Map(pageState.filters)

    if (!updatedFilters.get(category)) {
      updatedFilters.set(category, new Set())
    }

    if (category !== lastUpdatedCategory.current?.name) {
      lastUpdatedCategory.current = {
        name: category,
        value: availableFacets[category] ?? {}
      }
    }

    const updatedCategory = new Set(updatedFilters.get(category) as Set<string>)

    if (enabled) {
      updatedCategory.add(value)
    } else {
      updatedCategory.delete(value)
    }

    if (updatedCategory.size < 1) {
      updatedFilters.delete(category)
    } else {
      updatedFilters.set(category, updatedCategory)
    }

    if (updatedFilters.size < 1) {
      clearLastUpdatedCategory()
    }

    dispatch({
      type: OrderRequestsPageAction.UPDATE_FILTERS,
      value: updatedFilters
    })
  }

  const handleClearFilters = (): void => {
    clearLastUpdatedCategory()

    dispatch({ type: OrderRequestsPageAction.CLEAR_FILTERS })

    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.delete('filters')
    newQueryParams.set('page', '1')
    setQueryParams(newQueryParams)
  }

  const handleApplyFilters = (): void => {
    updateSearchParams()
  }

  const updateSearchParams = (): void => {
    clearLastUpdatedCategory()

    const newQueryParams = new URLSearchParams(queryParams)
    const filtersParam = filtersMapToParam(pageState.filters)

    newQueryParams.set('sort', sort)
    newQueryParams.set('page', '1')
    filtersParam && filtersParam.length > 0
      ? newQueryParams.set('filters', filtersParam.toString())
      : newQueryParams.delete('filters')

    if (isNewSearch) {
      dispatch({ type: OrderRequestsPageAction.CLEAR_FILTERS })
      newQueryParams.set('term', pageState.term.trim())
      newQueryParams.delete('filters')
    }

    dispatch({
      type: OrderRequestsPageAction.PATCH_STATE,
      value: {
        filtersUpdated: false,
        showOffcanvasFilters: false,
        term: pageState.term.trim()
      }
    })

    setQueryParams(newQueryParams)
  }

  return {
    data,
    isLoading,
    term: pageState.term,
    isNewSearch,
    searchType,
    dateRange,
    sort: { field: sort, direction },
    filters: pageState.filters,
    filtersUpdated: pageState.filtersUpdated,
    initialFacets,
    availableFacets: patchedAvailableFacets,
    emptyFacets,
    showOffcanvasFilters: pageState.showOffcanvasFilters,
    appliedFiltersCount,
    handlePageChange,
    handleDateRangeChange,
    handleSearchChange,
    handleSearchTypeChange,
    handleSortingChange,
    handleSubmit,
    handleOpenOffcanvasFilters,
    handleCloseOffcanvasFilters,
    handleApplyFilters,
    handleFilterUpdate,
    handleClearFilters,
    filterNameFormatters
  }
}

export default useOrderRequestsPage
