import { type ReactElement } from 'react'
import { ReactComponent as AmiciLogoRotate } from '../assets/images/amici-logo-rotate.svg'

function LoadingSpinner (): ReactElement {
  return (
    <div className="d-flex justify-content-center">
      <AmiciLogoRotate />
    </div>
  )
}

export default LoadingSpinner
