import {
  type ForwardedRef,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement
} from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import IconCountBadge from './IconCountBadge'
import { Link } from 'react-router-dom'
import styles from '../assets/scss/MaIconButton.module.scss'
import { type NavigationTarget } from '../types/navigation-target'

export interface MaIconButtonProps extends HTMLAttributes<HTMLElement> {
  variant?: 'primary' | 'secondary'
  activeBarPosition?: 'top' | 'right' | 'bottom' | 'left'
  active?: boolean
  count?: number
  to?: string
  target?: NavigationTarget
  forwardedRef?: ForwardedRef<HTMLButtonElement> | null
}

function MaIconButton ({
  forwardedRef,
  className,
  children,
  count,
  to,
  active,
  variant = 'primary',
  activeBarPosition = 'bottom',
  ...props
}: MaIconButtonProps & PropsWithChildren): ReactElement {
  return to
    ? (
    <Link
      to={to}
      {...props}
      className={classNames(
        'btn',
        'btn-light',
        styles['ma-icon-btn'],
        variant,
        { active },
        `active-bar-${activeBarPosition}`,
        className
      )}
    >
      {children}
      <IconCountBadge count={count ?? 0} />
    </Link>
      )
    : (
    <Button
      ref={forwardedRef}
      variant="light"
      className={classNames(
        styles['ma-icon-btn'],
        variant,
        { active },
        `active-bar-${activeBarPosition}`,
        className
      )}
      {...props}
    >
      {children}
      <IconCountBadge count={count ?? 0} />
    </Button>
      )
}

export default MaIconButton
