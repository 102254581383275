import { Dropdown, NavDropdown } from 'react-bootstrap'
import {
  type HTMLAttributes,
  type ReactElement,
  forwardRef,
  useState
} from 'react'
import { BsPerson } from 'react-icons/bs'
import AccountSwitcher from './AccountSwitcher'
import ProfileAvatar from './ProfileAvatar'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MaIconButton, { type MaIconButtonProps } from './MaIconButton'
import useAccountMenu from '../hooks/useAccountMenu'
import styles from '../assets/scss/AccountMenu.module.scss'

const AccountMenuButton = forwardRef<
HTMLButtonElement,
HTMLAttributes<HTMLButtonElement> & MaIconButtonProps
>(({ onClick, ...props }, ref) => {
  return (
    <MaIconButton
      forwardedRef={ref}
      onClick={onClick}
      className="d-none d-md-block"
      data-testid="account-menu-toggle-btn"
      {...props}
    >
      <BsPerson size="24" />
    </MaIconButton>
  )
})
AccountMenuButton.displayName = 'Account'

function AccountMenu (): ReactElement {
  const { t } = useTranslation()
  const {
    MA3_BASE_URL,
    accountProfile,
    canSwitchAccounts,
    canChangePassword,
    canSetupNewPin,
    canChangePin,
    handleLogOut
  } = useAccountMenu()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <Dropdown
      title="Account"
      align="end"
      className={styles['account-menu']}
      onToggle={show => {
        setIsExpanded(show)
      }}
    >
      <Dropdown.Toggle as={AccountMenuButton} active={isExpanded} />
      <Dropdown.Menu className={styles['account-menu']}>
        <Dropdown.ItemText className={styles['company-name']}>
          {accountProfile?.client?.name}
        </Dropdown.ItemText>
        <Dropdown.ItemText className={styles.username}>
          <ProfileAvatar fullName={accountProfile?.name ?? ''} />{' '}
          {accountProfile?.name}{' '}
          <span className={styles['account-id']}>({accountProfile?.id})</span>
        </Dropdown.ItemText>
        <Dropdown.Item as={Link} to="/user-account">
          {t('account_menu.my_user_account')}
        </Dropdown.Item>
        {canChangePassword && (
          <Dropdown.Item
            href={`${MA3_BASE_URL}/MyAccount/ChangePassword.aspx`}
            target="_blank"
          >
            {t('account_menu.change_password')}
          </Dropdown.Item>
        )}
        {canSetupNewPin && (
          <Dropdown.Item
            href={`${MA3_BASE_URL}/MyAccount/ResetPIN.aspx`}
            target="_blank"
          >
            {t('account_menu.setup_new_pin')}
          </Dropdown.Item>
        )}
        {canChangePin && (
          <Dropdown.Item
            href={`${MA3_BASE_URL}/MyAccount/ChangePin.aspx`}
            target="_blank"
          >
            {t('account_menu.change_pin')}
          </Dropdown.Item>
        )}
        {canSwitchAccounts && (
          <div>
            <AccountSwitcher className="dropdown-item" />
          </div>
        )}
        <NavDropdown.Item onClick={handleLogOut}>
          {t('account_menu.log_out')}
        </NavDropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default AccountMenu
