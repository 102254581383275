import { useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'

export const useEnvHook = (): any => {
  const [env, setEnv] = useState({})

  useEffect(() => {
    if (Object.keys(env).length === 0) {
      const envApiPath =
        (process.env.REACT_APP_ENV_API_HOST !== undefined
          ? process.env.REACT_APP_ENV_API_HOST
          : '') + '/api/env'

      fetch(envApiPath)
        .then(async response => {
          if (response.ok) {
            return await response.json()
          } else {
            throw new Error('Could not read env response')
          }
        })
        .then((apiEnv): void => {
          setEnv({
            ...(process.env as Record<string, string>),
            ...(apiEnv as Record<string, string>)
          })
        })
        .catch(error => {
          if (!document) {
            return
          }

          console.log('Could not load env: ' + (error as string))
          setEnv(process.env)
        })
    }
  }, [env])

  return env
}

export const useEnv = singletonHook({}, useEnvHook)
