import { type ReactElement, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import useNotifications from '../hooks/useNotifications'
import NotificationList from '../components/NotificationList'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

enum FilterValues {
  ALL,
  READ,
  UNREAD,
}

function NotificationsPage (): ReactElement {
  const { t } = useTranslation()
  const {
    notifications,
    readNotifications,
    unreadNotifications,
    markAllAsRead,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading
  } = useNotifications()
  const [filter, setFilter] = useState(FilterValues.ALL)

  return (
    <Container fluid="auto" className="ma-page notifications-page">
      <Row>
        <Col sm="12">
          <h1 className="title notifications-page__title">
            {t('notifications.title')}
          </h1>
        </Col>
      </Row>

      <div className="notifications-page__content-wrapper">
        <div className="notifications-page__filter">
          <Button
            variant="light"
            active={filter === FilterValues.ALL}
            className="d-flex justify-content-between"
            onClick={() => {
              ReactGA.event('filter_item_list', {
                item_list_id: 'notifications',
                filter_field: 'read',
                filter_value: FilterValues.ALL
              })
              setFilter(FilterValues.ALL)
            }}
            data-testid="btn-filter-all"
          >
            <span>{t('notifications.filter.all')}</span>{' '}
            <span>{notifications.length}</span>
          </Button>
          <Button
            variant="light"
            active={filter === FilterValues.UNREAD}
            className="d-flex justify-content-between"
            onClick={() => {
              ReactGA.event('filter_item_list', {
                item_list_id: 'notifications',
                filter_field: 'read',
                filter_value: FilterValues.UNREAD
              })
              setFilter(FilterValues.UNREAD)
            }}
            data-testid="btn-filter-unread"
          >
            <span>{t('notifications.filter.unread')}</span>{' '}
            <span>{unreadNotifications.length}</span>
          </Button>
          <Button
            variant="light"
            active={filter === FilterValues.READ}
            className="d-flex justify-content-between"
            onClick={() => {
              ReactGA.event('filter_item_list', {
                item_list_id: 'notifications',
                filter_field: 'read',
                filter_value: FilterValues.READ
              })
              setFilter(FilterValues.READ)
            }}
            data-testid="btn-filter-read"
          >
            <span>{t('notifications.filter.read')}</span>{' '}
            <span>{readNotifications.length}</span>
          </Button>
        </div>

        <div className="notifications-page__content" data-testid="page-content">
          <NotificationList
            totalCount={notifications?.length ?? 0}
            notifications={
              {
                [FilterValues.ALL]: notifications,
                [FilterValues.READ]: readNotifications,
                [FilterValues.UNREAD]: unreadNotifications
              }[filter]
            }
          />
        </div>
      </div>

      <Row className="notifications-page__footer">
        <Col md={{ span: 10, offset: 2 }}>
          <Button
            variant="light"
            onClick={() => {
              void markAllAsRead()
            }}
            data-testid="btn-mark-all-read"
            disabled={unreadNotifications.length < 1}
          >
            {t('notifications.action.mark_all_as_read')}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default NotificationsPage
