import { type HTMLAttributes, type ReactElement } from 'react'
import { type PurchaseOrderResource } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'

function PurchaseOrderDeliveryInstructions ({
  order,
  ...props
}: HTMLAttributes<HTMLElement> & {
  order?: PurchaseOrderResource
}): ReactElement | null {
  const { t } = useTranslation()

  if (!order || !order?.delivery_notes) {
    return null
  }

  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header
        text={t('purchase_order.delivery_instructions.title')}
        className={styles.header}
      />
      <MaPanel.Body className={styles.notes}>
        {order.delivery_notes}
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderDeliveryInstructions
