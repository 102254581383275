import { type ReactElement, type HTMLAttributes } from 'react'
import {
  type OrderRequestReference,
  type OrderLineItemResource
} from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'
import OrderLineItems from './OrderLineItems'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function PurchaseOrderOrderItems ({
  lineItems,
  orderRequestReference,
  ...props
}: HTMLAttributes<HTMLElement> & {
  lineItems?: OrderLineItemResource[]
  orderRequestReference?: OrderRequestReference | null
}): ReactElement {
  const { t } = useTranslation()
  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header>
        <h5 className={styles.title}>
          <span>
            {orderRequestReference
              ? (
              <Trans
                i18nKey={t(
                  'purchase_order.order_items.title_with_order_request_link'
                )}
                values={{
                  count: lineItems?.length ?? 0
                }}
                components={{
                  OrderRequestLink: (
                    <Link
                      to={`/purchasing/order-requests/${orderRequestReference?.id}`}
                    >
                      {orderRequestReference?.reference}
                    </Link>
                  )
                }}
              />
                )
              : (
              <Trans
                i18nKey={t('purchase_order.order_items.title')}
                values={{
                  count: lineItems?.length ?? 0
                }}
              />
                )}
          </span>
        </h5>
      </MaPanel.Header>
      <MaPanel.Body>
        <OrderLineItems items={lineItems} max={10} showAllOption={true} />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderOrderItems
