import { useRef, useState } from 'react'
import {
  type StocksApiGetSupplierProductStockCheckRequest,
  type BasketLineItem,
  type StockCheckResource
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

function useBasketStockInfo (
  items: BasketLineItem[]
): Map<string, StockCheckResource> {
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId

  const {
    fetcher,
    stocksApi: { getSupplierProductStockCheck }
  } = useApi()

  const stockInfoRequests = useRef<
  Map<string, Promise<StockCheckResource | Error>>
  >(new Map())

  const [stockInfo, setStockInfo] = useState<Map<string, StockCheckResource>>(
    new Map()
  )

  if (items.length < 1 || !accountId) {
    return stockInfo
  }

  items.forEach(({ line_item: lineItem }) => {
    if (!lineItem || stockInfoRequests.current.has(lineItem.id)) {
      return
    }

    stockInfoRequests.current.set(
      lineItem.id,
      fetcher(getSupplierProductStockCheck, {
        productId: lineItem.product.id,
        quantity: lineItem.quantity,
        accountId
      } satisfies StocksApiGetSupplierProductStockCheckRequest)
        .then(data => {
          setStockInfo(stockInfo => new Map(stockInfo).set(lineItem.id, data))

          return data
        })
        .catch((error: Error) => {
          console.warn(
            `Could not fetch stock info for the product: ${lineItem.product.id}`
          )

          return error
        })
    )
  })

  return stockInfo
}

export default useBasketStockInfo
