import type UserAccount from '../../common/models/UserAccount'
import { UserModule } from '../../common/types/user-module'
import { UserPermission } from '../../common/types/user-permission'

export interface UsePurchasingPermissionsReturn {
  isFinanceUser: boolean
  hasFinanceModule: boolean
}

function usePurchasingPermissions (
  account: UserAccount | null
): UsePurchasingPermissionsReturn {
  const isFinanceUser = !!account?.permissions?.some(permission =>
    [UserPermission.IsFinance, UserPermission.IsFinanceUser].includes(
      permission as UserPermission
    )
  )
  const hasFinanceModule = !!account?.modules?.find(
    module => module === UserModule.HasFinanceModule
  )

  return { isFinanceUser, hasFinanceModule }
}

export default usePurchasingPermissions
