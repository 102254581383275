import { forwardRef, type PropsWithChildren, type ReactElement } from 'react'
import { Button, type ModalProps } from 'react-bootstrap'
import { BsQuestionCircleFill, BsExclamationTriangleFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import MaModal from './MaModal'
import styles from '../assets/scss/MaConfirm.module.scss'

interface MaConfirmProps extends ModalProps, PropsWithChildren {
  variant?: 'default' | 'question' | 'warning'
  confirmLabel?: string
  closeLabel?: string
  disabled?: boolean
  onConfirm: () => void
  onClose: () => void
}

const MaConfirm = forwardRef(function MaConfirm (
  {
    className,
    confirmLabel,
    closeLabel,
    disabled = false,
    variant = 'default',
    onConfirm,
    onClose,
    ...props
  }: MaConfirmProps,
  ref
): ReactElement {
  const { t } = useTranslation()

  return (
    <MaModal
      {...props}
      ref={ref}
      onClose={onClose}
      className={classNames(
        styles['ma-confirm'],
        {
          [styles.question]: variant === 'question',
          [styles.warning]: variant === 'warning'
        },
        className
      )}
      {...(variant === 'question' && {
        header: (
          <div className={styles.header}>
            <BsQuestionCircleFill size={24} />
            <h4>{props.title}</h4>
          </div>
        )
      })}
      {...(variant === 'warning' && {
        header: (
          <div className={styles.header}>
            <BsExclamationTriangleFill size={24} />
            <h4>{props.title}</h4>
          </div>
        )
      })}
      footer={
        <div className={styles.buttons}>
          <Button
            variant="outline-danger"
            onClick={onClose}
            className={styles.close}
          >
            {closeLabel || t('common.button.labels.close')}
          </Button>

          <Button
            onClick={onConfirm}
            disabled={disabled}
            className={styles.confirm}
          >
            {confirmLabel || t('common.button.labels.confirm')}
          </Button>
        </div>
      }
    />
  )
})

export default MaConfirm
