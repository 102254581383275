import useSWR from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  type OrderRequestBase,
  type OrderRequestsApiUpdateOrderRequestRequest,
  type OrderRequestResource,
  type OrderRequestsApiApproveOrderRequestRequest,
  type OrderRequestsApiCancelOrderRequestRequest,
  type OrderRequestsApiRejectOrderRequestRequest,
  type OrderRequestsApiWithdrawOrderRequestRequest,
  type OrderRequestsApiSubmitOrderRequestRequest
} from '@amici/myamici-api-client'
import useSWRMutation from 'swr/mutation'

interface UseOrderRequestHook {
  data?: OrderRequestResource
  isLoading: boolean
  isUpdating: boolean
  isCancelling: boolean
  isApproving: boolean
  isRejecting: boolean
  isWithdrawing: boolean
  isSubmitting: boolean
  update: (
    orderRequest: OrderRequestBase,
  ) => Promise<OrderRequestResource | undefined>
  cancel: (reason: string) => Promise<void>
  approve: (response: string) => Promise<void>
  reject: (reason: string) => Promise<void>
  withdraw: (reason: string) => Promise<void>
  submit: () => Promise<void>
}

function useOrderRequest (orderRequestId: string = ''): UseOrderRequestHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequest,
      updateOrderRequest,
      cancelOrderRequest,
      approveOrderRequest,
      rejectOrderRequest,
      withdrawOrderRequest,
      submitOrderRequest
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR<OrderRequestResource, Error>(
    accountId && orderRequestId
      ? ['order-request', orderRequestId, accountId]
      : null,
    async () => await fetcher(getOrderRequest, { orderRequestId, accountId })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation<
  OrderRequestResource,
  Error,
  ['order-request', string, string] | null,
  OrderRequestsApiUpdateOrderRequestRequest
  >(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }) => await fetcher(updateOrderRequest, arg),
    { populateCache: true, revalidate: false }
  )

  const { trigger: triggerCancel, isMutating: isCancelling } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiCancelOrderRequestRequest }) => {
      await fetcher(cancelOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerApprove, isMutating: isApproving } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiApproveOrderRequestRequest }) => {
      await fetcher(approveOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerReject, isMutating: isRejecting } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiRejectOrderRequestRequest }) => {
      await fetcher(rejectOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerWithdraw, isMutating: isWithdrawing } =
    useSWRMutation(
      accountId ? ['order-request', orderRequestId, accountId] : null,
      async (
        _,
        { arg }: { arg: OrderRequestsApiWithdrawOrderRequestRequest }
      ) => {
        await fetcher(withdrawOrderRequest, arg)
      },
      { populateCache: false, revalidate: true }
    )

  const { trigger: triggerSubmit, isMutating: isSubmitting } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiSubmitOrderRequestRequest }) => {
      await fetcher(submitOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const update = async (
    orderRequest: OrderRequestBase
  ): Promise<OrderRequestResource | undefined> => {
    if (!accountId || !orderRequestId) {
      return
    }

    return await triggerUpdate({
      orderRequestBase: orderRequest,
      orderRequestId,
      accountId
    })
  }

  const cancel = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerCancel({
        cancellationReason: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  const approve = async (response: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerApprove({
        approvalResponse: { response },
        orderRequestId,
        accountId
      })
    }
  }

  const reject = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerReject({
        rejectOrderRequestRequest: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  const withdraw = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerWithdraw({
        withdrawOrderRequestRequest: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  const submit = async (): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerSubmit({
        orderRequestId,
        accountId
      })
    }
  }

  return {
    data,
    update,
    cancel,
    approve,
    reject,
    withdraw,
    submit,
    isLoading,
    isUpdating,
    isCancelling,
    isApproving,
    isRejecting,
    isWithdrawing,
    isSubmitting
  }
}

export default useOrderRequest
