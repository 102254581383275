import useSWR from 'swr'
import {
  type OrderLineItemResource,
  type ReorderResponse
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

export interface UseValidateReorderHook {
  data: ReorderResponse
  successfulReorderItems: OrderLineItemResource[]
  unsuccessfulReorderItems: OrderLineItemResource[]
  isLoading: boolean
  reorderDisabled: boolean
}

function useValidateReorder (orderId: string): UseValidateReorderHook {
  const { ordersApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR(
    orderId && accountId ? ['validate-reorder', orderId, accountId] : null,
    async () =>
      await fetcher(ordersApi.validateReorder, { orderId, accountId })
  )

  const successfulReorderItems = data?.successful_line_items ?? []
  const unsuccessfulReorderItems = data?.unsuccessful_line_items ?? []

  const reorderDisabled =
    isLoading ||
    successfulReorderItems.length === 0 ||
    successfulReorderItems.some(item => item.is_self_serve)

  return {
    data: data as ReorderResponse,
    successfulReorderItems,
    unsuccessfulReorderItems,
    isLoading,
    reorderDisabled
  }
}

export default useValidateReorder
