import { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { type OrderRequestLineItem } from '@amici/myamici-api-client'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import MaToast from '../../common/components/MaToast'
import MaConfirm from '../../common/components/MaConfirm'

export interface OrderRequestRemoveLineItemModalProps {
  orderRequestId: string
  lineItem?: OrderRequestLineItem
  onClose: () => void
}

function OrderRequestRemoveLineItemModal ({
  orderRequestId,
  lineItem,
  onClose
}: Readonly<OrderRequestRemoveLineItemModalProps>): ReactNode {
  const { t } = useTranslation()
  const { removeLineItem, isRemoving } =
    useOrderRequestLineItems(orderRequestId)
  const { showToast, closeToast } = useToastNotification()
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)

  const handleConfirm = async (): Promise<void> => {
    if (!lineItem) {
      return
    }

    const product = lineItem.line_item.product
    const productLink = `/purchasing/products/${product.id}`
    const toastId = Date.now()

    try {
      await removeLineItem(lineItem)
      await refreshHistory()

      showToast(
        toastId,
        <MaToast
          type="dark"
          onClose={() => {
            closeToast(toastId)
          }}
        >
          <p>
            <Link to={productLink}>
              {lineItem.line_item.product.description}
            </Link>{' '}
            {t('order_request.remove.success')}
          </p>
        </MaToast>
      )
    } catch (error) {
      showToast(
        toastId,
        <MaToast
          type="danger"
          onClose={() => {
            closeToast(toastId)
          }}
        >
          <p>{t('order_request.remove.failure')}</p>
        </MaToast>
      )
    } finally {
      onClose()
    }
  }

  const handleClose = (): void => {
    if (isRemoving) {
      return
    }

    onClose()
  }

  return (
    <MaConfirm
      variant="question"
      show={lineItem}
      disabled={isRemoving}
      title={t('order_request.remove.confirmation.title')}
      closeLabel={t('common.button.labels.cancel')}
      confirmLabel={t('common.button.labels.ok')}
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <p>
        {t('order_request.remove.confirmation.line_1', {
          description: lineItem?.line_item.product.description
        })}
      </p>
      <p>{t('order_request.remove.confirmation.line_2')}</p>
    </MaConfirm>
  )
}

export default OrderRequestRemoveLineItemModal
