import {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { type CardProps } from 'react-bootstrap'
import {
  type PurchaseOrderResource,
  type OrderLineItemResource,
  type OrderResource
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useIsMobile from '../../common/hooks/useIsMobile'
import useBasketCount from '../../basket/hooks/useBasketCount'
import useBasketPreview from '../../basket/hooks/useBasketPreview'
import useBasketSettings from '../../basket/hooks/useBasketSettings'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaConfirm from '../../common/components/MaConfirm'
import styles from '../assets/scss/OrderCard.module.scss'

function ModalContent ({
  validForReorder,
  count,
  poRef
}: Readonly<{
  validForReorder: boolean
  count: number
  poRef: string
}>): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <p className="small">
        {validForReorder
          ? t('order.card.buy_again.added_to_basket', {
            count,
            poRef
          })
          : t('order.card.buy_again.inactive_products_warning')}
      </p>
      <p className="small">{t('order.card.buy_again.wish_to_continue')}</p>
    </>
  )
}

interface BuyAgainProps extends CardProps, PropsWithChildren {
  order: OrderResource | PurchaseOrderResource
  successfulOrderItems: OrderLineItemResource[]
  unsuccessfulOrderItems: OrderLineItemResource[]
  show: boolean
  onClose: () => void
}

function BuyAgainModal ({
  order,
  successfulOrderItems,
  unsuccessfulOrderItems,
  show,
  onClose
}: Readonly<BuyAgainProps>): ReactElement {
  const { t } = useTranslation()
  const { ordersApi } = useApi()
  const { basketUrl, target } = useBasketSettings()
  const { mutate: updateBasketCount } = useBasketCount()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const [basketPending, setBasketPending] = useState(false)
  const { showToastMessage } = useToastNotification()
  const { activeAccount } = useAccounts()
  const isMobile = useIsMobile()

  const validForReorder = unsuccessfulOrderItems.length === 0

  const showSuccessToastMessage = useCallback(
    (count: number): void => {
      showToastMessage(
        'dark',
        <>
          <p>
            {t('product.details.add_to_basket.success', {
              count
            })}
          </p>
          <Link to={basketUrl} target={target}>
            {t('product.details.add_to_basket.basket_link')}
          </Link>
        </>
      )
    },
    [basketUrl, showToastMessage, t, target]
  )

  const showFailureToastMessage = useCallback((): void => {
    showToastMessage(
      'danger',
      <p>{t('product.details.add_to_basket.failure')}</p>
    )
  }, [showToastMessage, t])

  const handleCopyToBasket = async (): Promise<void> => {
    const accountId = activeAccount?.accountId ?? ''
    const orderId = order?.id

    if (!activeAccount?.accountId || !order.id) return

    try {
      setBasketPending(true)
      const response = await ordersApi.reorder({ orderId, accountId })
      await updateBasketCount()
      await updateBasketPreview()

      const successfulLineItemsQty = (
        response.data?.successful_line_items ?? []
      ).length

      if (successfulLineItemsQty > 0) {
        showSuccessToastMessage(successfulLineItemsQty)
      } else {
        showFailureToastMessage()
      }
    } catch (e) {
      showFailureToastMessage()
    } finally {
      setBasketPending(false)
      onClose()
    }
  }

  return (
    <>
      <MaConfirm
        size={isMobile ? 'sm' : undefined}
        variant={validForReorder ? 'question' : 'warning'}
        show={show}
        title={
          validForReorder
            ? t('order.card.buy_again.confirmation.title')
            : t('common.title.warning')
        }
        closeLabel={t('common.button.labels.cancel')}
        confirmLabel={t('common.button.labels.ok')}
        disabled={basketPending}
        onConfirm={() => {
          void handleCopyToBasket()
        }}
        onClose={onClose}
        className={styles.confirmation}
      >
        <ModalContent
          validForReorder={validForReorder}
          count={successfulOrderItems.length}
          poRef={order.reference}
        />
      </MaConfirm>
    </>
  )
}
export default BuyAgainModal
