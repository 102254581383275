import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import { type LineItems } from '@amici/myamici-api-client'

interface UsePurchaseHistoryHook {
  data: LineItems
  error: any
  isLoading: boolean
  hasData: boolean
}

function usePurchaseHistory (productId?: string): UsePurchaseHistoryHook {
  const { activeAccount } = useAccounts()
  const {
    productsApi: { getPurchaseHistory },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWR(
    productId && accountId
      ? ['product-purchase-history', productId, accountId]
      : null,
    async () =>
      await fetcher(getPurchaseHistory, {
        productId: productId ?? '',
        accountId,
        page: 1,
        size: 10
      })
  )

  const hasData: boolean = !!(data?.totalElements && data?.totalElements > 0)

  return {
    data: data as LineItems,
    error,
    isLoading,
    hasData
  }
}

export default usePurchaseHistory
