import {
  type StockCheckResource,
  type BasketLineItem,
  ProductStockAvailabilityEnum,
  ProductStatusEnum
} from '@amici/myamici-api-client'

export enum ChangeType {
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
  OutOfStock = 'OUT_OF_STOCK',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  PackSize = 'PACK_SIZE',
  ProductName = 'PRODUCT_NAME',
  PriceIncrease = 'PRICE_INCREASE',
  PriceDecrease = 'PRICE_DECREASE',
}

export interface BasketLineItemChange {
  id: string
  changeType: ChangeType
  item: BasketLineItem
}

function getChanges (
  changeType: ChangeType,
  items: BasketLineItem[],
  stockInfo: Map<string, StockCheckResource>
): BasketLineItemChange[] {
  const filterPredicate = {
    [ChangeType.PriceIncrease]: (item: BasketLineItem): boolean =>
      !!item.product_price &&
      +(item.product_price ?? 0).toFixed(2) >
        +(item.line_item.price ?? 0).toFixed(2),

    [ChangeType.PriceDecrease]: (item: BasketLineItem): boolean =>
      !!item.product_price &&
      +(item.product_price ?? 0).toFixed(2) <
        +(item.line_item.price ?? 0).toFixed(2),

    [ChangeType.OutOfStock]: (item: BasketLineItem): boolean =>
      stockInfo.get(item.line_item?.id ?? '')?.availability ===
      ProductStockAvailabilityEnum.NO_STOCK,

    [ChangeType.InsufficientStock]: (item: BasketLineItem): boolean => {
      const itemStockInfo = stockInfo.get(item.line_item.id ?? '')

      if (
        !itemStockInfo ||
        itemStockInfo.availability === ProductStockAvailabilityEnum.NO_STOCK ||
        itemStockInfo.availability ===
          ProductStockAvailabilityEnum.UNAVAILABLE ||
        itemStockInfo.availability === ProductStockAvailabilityEnum.MORE_THAN
      ) {
        return false
      }

      return (
        (itemStockInfo?.maximum_quantity ?? 0) < (item.line_item?.quantity ?? 0)
      )
    },

    [ChangeType.ProductUnavailable]: (item: BasketLineItem): boolean =>
      item.line_item?.product.status === ProductStatusEnum.INACTIVE,

    [ChangeType.ProductName]: (item: BasketLineItem): boolean =>
      item.line_item?.product.description !==
      item.product_snapshot?.description,

    [ChangeType.PackSize]: (item: BasketLineItem): boolean => {
      if (!item.product_snapshot || !item.line_item?.product) {
        return false
      }

      const {
        no_of_units: previousNoOfUnits,
        pack_size: previousPackSize,
        pack_size_unit: previousPackSizeUnit
      } = item.product_snapshot

      const {
        no_of_units: currentNoOfUnits,
        pack_size: currentPackSize,
        pack_size_unit: currentPackSizeUnit
      } = item.line_item.product

      return (
        previousNoOfUnits !== currentNoOfUnits ||
        previousPackSize !== currentPackSize ||
        previousPackSizeUnit !== currentPackSizeUnit
      )
    }
  }

  return items
    .filter(filterPredicate[changeType])
    .map(item => ({
      id: `${changeType}_${item.line_item?.id}`,
      changeType,
      item
    }))
    .sort((a, b) => {
      const supplierA = a.item.line_item?.product.supplier?.name ?? ''
      const supplierB = b.item.line_item?.product.supplier?.name ?? ''
      const productA = a.item.line_item?.product.description ?? ''
      const productB = b.item.line_item?.product.description ?? ''

      return supplierA.localeCompare(supplierB) === 0
        ? productA.localeCompare(productB)
        : supplierA.localeCompare(supplierB)
    })
}

function useBasketItemChanges (
  items: BasketLineItem[],
  stockInfo: Map<string, StockCheckResource>
): BasketLineItemChange[] {
  return [
    ...getChanges(ChangeType.ProductUnavailable, items, stockInfo),
    ...getChanges(ChangeType.OutOfStock, items, stockInfo),
    ...getChanges(ChangeType.InsufficientStock, items, stockInfo),
    ...getChanges(ChangeType.PriceIncrease, items, stockInfo),
    ...getChanges(ChangeType.PackSize, items, stockInfo),
    ...getChanges(ChangeType.ProductName, items, stockInfo),
    ...getChanges(ChangeType.PriceDecrease, items, stockInfo)
  ]
}

export default useBasketItemChanges
