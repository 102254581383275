import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  type ReactElement
} from 'react'
import * as Select from '@radix-ui/react-select'
import {
  SpendCategoryStatusEnum,
  type SpendCategory
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import {
  BsChevronDown,
  BsFillCaretDownFill,
  BsFillCaretRightFill
} from 'react-icons/bs'
import { type NodeRendererProps, Tree, type TreeApi } from 'react-arborist'
import { useTranslation } from 'react-i18next'
import { type SpendCategoryNode } from '../types/spend-category-node'
import '../../common/assets/scss/MaSelect.scss'
import useSpendCategoriesTree from '../hooks/useSpendCategoriesTree'
import styles from '../assets/scss/SpendCategoriesTree.module.scss'

const ROW_HEIGHT_PX = 44

interface NodeProps extends NodeRendererProps<SpendCategoryNode> {
  selected?: SpendCategory
  onToggle: (id: string) => void
  onSelect: (node: SpendCategoryNode) => void
}

function Node ({
  node,
  style,
  selected,
  onToggle,
  onSelect
}: Readonly<NodeProps>): ReactElement {
  const { t } = useTranslation()

  // The -1 checks are to cover the special case when an "empty" option is inserted
  const formattedName =
    node.data.id !== -1
      ? `${node.data.name} - ${node.data.code}`
      : t('spend_categories.label.none')
  const formattedExternalRef =
    node.data.id !== -1
      ? `${node.data.external_ref && ` (${node.data.external_ref})`}`
      : ''
  const formattedDescription =
    node.data.id !== -1
      ? `${`${node.data.description ? ' -' : ''}`} ${node.data.description}`
      : ''

  const title = formattedName + formattedExternalRef + formattedDescription

  return (
    <div style={style} className={classNames(styles['node-wrapper'])}>
      <div
        className={classNames(styles.node, {
          selected: selected?.id === node.data.id
        })}
        title={title}
        onClick={e => {
          e.stopPropagation()
          onSelect(node.data)
        }}
      >
        <span className={classNames(styles['node-name'])}>
          <span
            role="button"
            className={styles['node-icon']}
            onClick={e => {
              e.stopPropagation()
              onToggle(node.id)
            }}
          >
            {(node.children?.length ?? 0) < 1
              ? null
              : node.state.isOpen
                ? (
              <BsFillCaretDownFill size={16} />
                  )
                : (
              <BsFillCaretRightFill size={16} />
                  )}
          </span>
          {formattedName}
          {formattedExternalRef}
          <span className={styles['node-description']}>
            {formattedDescription}
          </span>
        </span>
      </div>
    </div>
  )
}

export interface SpendCategorySelectProps extends Select.SelectProps {
  id?: string
  spendCategories: SpendCategory[]
  placeholder?: string
  className?: string
  allowEmpty: boolean
}

function SpendCategorySelect ({
  spendCategories,
  value,
  placeholder,
  className,
  allowEmpty,
  onValueChange,
  ...props
}: Readonly<SpendCategorySelectProps>): ReactElement {
  const [open, setOpen] = useState(false)
  const { tree, getPath, getEmptySpendCategory } = useSpendCategoriesTree(
    spendCategories
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(
        spendCategory =>
          spendCategory.status === SpendCategoryStatusEnum.ACTIVE
      )
  )

  const spendCategoryId = value ? parseInt(value, 10) : null

  const selectedSpendCategory = spendCategories.find(
    spendCategory => spendCategory.id === spendCategoryId
  )

  const formattedValue = selectedSpendCategory
    ? `${selectedSpendCategory?.name} - ${selectedSpendCategory.code}`
    : placeholder

  const nodePath = spendCategoryId
    ? getPath(spendCategoryId).filter(id => !!id)
    : []

  const formattedPath =
    nodePath
      .filter(id => id !== selectedSpendCategory?.id)
      .map(
        id =>
          spendCategories.find(spendCategory => spendCategory.id === id)?.name
      )
      .join(' / ') + `${nodePath.length > 1 ? ' / ' : ''}${formattedValue}`

  const treeRef = useRef<TreeApi<SpendCategoryNode> | undefined>()

  const [containerHeight, setContainerHeight] = useState(0)

  // The tree's internal state is exposed via a ref,
  // so an extra re-render is needed to pick up the most recent change.
  // This is an empty state which is needed to trigger a forced update.
  const [update, updateState] = useState({})
  const forceUpdate = useCallback(() => {
    updateState({})
  }, [])

  useLayoutEffect(() => {
    const nodeCount = treeRef.current?.visibleNodes.length ?? 0
    setContainerHeight(nodeCount * ROW_HEIGHT_PX)
  }, [open, update])

  const handleNodeToggle = (id: string): void => {
    treeRef.current?.isOpen(id)
      ? treeRef.current?.close(id)
      : treeRef.current?.open(id)

    forceUpdate()
  }

  const handleNodeSelect = (node: SpendCategoryNode): void => {
    onValueChange?.(node.id.toString())
    setOpen(false)
  }

  return (
    <Select.Root
      open={open}
      onOpenChange={open => {
        setOpen(open)
      }}
      {...props}
    >
      <Select.Trigger
        id={props.id}
        className={classNames('ma-select-trigger', className)}
        title={selectedSpendCategory && formattedPath}
      >
        <span
          className={classNames({
            'ma-select-value': selectedSpendCategory
          })}
        >
          {formattedValue}
        </span>

        <Select.Icon className="ma-select-icon">
          <BsChevronDown />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          position="popper"
          className={classNames(
            'ma-select-content',
            styles['dropdown-content']
          )}
        >
          <Select.Viewport
            className={classNames(
              'ma-select-viewport',
              styles['dropdown-viewport']
            )}
          >
            <Tree
              data={[
                ...(allowEmpty
                  ? [getEmptySpendCategory(spendCategories[0].field_id)]
                  : []),
                ...tree
              ]}
              width="100%"
              height={containerHeight}
              disableEdit={true}
              disableMultiSelection={true}
              idAccessor="node_id"
              rowHeight={ROW_HEIGHT_PX}
              disableDrop={true}
              disableDrag={true}
              ref={treeRef}
            >
              {props =>
                Node({
                  selected: selectedSpendCategory,
                  onToggle: handleNodeToggle,
                  onSelect: handleNodeSelect,
                  ...props
                })
              }
            </Tree>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

export default SpendCategorySelect
