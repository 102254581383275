import { type ReactElement, useState } from 'react'
import useMainNavigation from '../hooks/useMainNavigation'
import { Nav, NavDropdown } from 'react-bootstrap'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsChevronDown } from 'react-icons/bs'
import classNames from 'classnames'
import { type NavigationItem, type NavigationSection } from '../models/NavigationSection'
import styles from '../assets/scss/HeaderNavigation.module.scss'

function HeaderNavigation (): ReactElement {
  const { t } = useTranslation()
  const mainNavigation = useMainNavigation()
  const [activeSection, setActiveSection] = useState('')
  const location = useLocation()
  const [params] = useSearchParams()

  const isActiveSection = (section: NavigationSection): boolean =>
    section
      .getEnabledItems()
      .flat()
      .some(
        (item: NavigationItem) =>
          item.url && location.pathname.startsWith(item.url.split('?')[0])
      )

  return (
    <Nav className={classNames(styles['header-main-nav'])}>
      {mainNavigation.map(section => (
        <NavDropdown
          key={section.title}
          title={
            <span>
              {t(section.title)} <BsChevronDown size={16} />
            </span>
          }
          active={isActiveSection(section)}
          show={activeSection === section.title}
          onToggle={() => {
            setActiveSection(activeSection =>
              activeSection === section.title ? '' : section.title
            )
          }}
        >
          <div className={styles['dropdown-menu-group']}>
            {section
              .getEnabledItems()
              .map((group: NavigationItem[], i: number) => {
                if (group.length < 1) {
                  return null
                }

                return (
                  <div key={i} className="d-flex flex-column mx-2">
                    {group.map((item: NavigationItem) => (
                      <NavDropdown.Item
                        key={item.title}
                        className="text-nowrap"
                        as={Link}
                        to={item.url}
                        target={item.target}
                        active={
                          location.pathname.startsWith(
                            item.url.split('?')[0]
                          ) &&
                          (item.includeParams == null ||
                            item.includeParams?.every(param =>
                              params?.toString().includes(param)
                            )) &&
                          (item.excludeParams == null ||
                            item.excludeParams?.every(
                              param => !params?.toString().includes(param)
                            ))
                        }
                      >
                        {t(item.title)}
                      </NavDropdown.Item>
                    ))}
                  </div>
                )
              })}
          </div>
        </NavDropdown>
      ))}
    </Nav>
  )
}

export default HeaderNavigation
