import {
  SpendCategoryStatusEnum,
  type SpendCategory
} from '@amici/myamici-api-client'
import { type SpendCategoryNode } from '../types/spend-category-node'

function getSpendCategoriesTree (
  spendCategoryData: SpendCategory[]
): SpendCategoryNode[] {
  function getChildren (node: SpendCategoryNode): SpendCategoryNode {
    const children: SpendCategoryNode[] = spendCategoryData
      .filter(spendCategory => spendCategory.parent_id === node.id)
      .map(spendCategory => ({
        ...spendCategory,
        node_id: `${spendCategory.parent_id}_${spendCategory.id}`
      }))

    return {
      ...node,
      node_id: `${node.parent_id}_${node.id}`,
      children:
        children.length < 1
          ? []
          : children.map(childSpendCategory => getChildren(childSpendCategory))
    }
  }

  if (!spendCategoryData || spendCategoryData.length < 1) {
    return []
  }

  const rootNodes = spendCategoryData.filter(
    spendCategory => spendCategory.parent_id === null
  )

  return rootNodes.map(rootNode =>
    getChildren({
      ...rootNode,
      node_id: `${rootNode.parent_id}_${rootNode.id}`
    })
  )
}

function getPath (
  spendCategories: SpendCategory[],
  id: number
): Array<number | null> {
  function getParents (
    parents: Array<number | null>,
    id: number | null
  ): Array<number | null> {
    if (!id) {
      return parents
    }

    const item = spendCategories.find(item => item.id === id)
    const parentId = item?.parent_id ?? null

    return [...getParents([parentId, ...parents], parentId)]
  }

  return getParents([id], id)
}

function getHeight (
  spendCategories: SpendCategory[],
  node: SpendCategoryNode
): number {
  const getNodeSubtree = (
    children: SpendCategoryNode[],
    node: SpendCategoryNode
  ): SpendCategoryNode | undefined => {
    const foundNode = children.find(({ id }) => node.id === id)

    if (foundNode) {
      return foundNode
    }

    for (const child of children) {
      const foundNode = getNodeSubtree(child?.children ?? [], node)

      if (foundNode) {
        return foundNode
      }
    }
  }

  const getSubtreeHeight = (node: SpendCategoryNode): number =>
    node.children && node.children?.length < 1
      ? 0
      : Math.max(
        ...(node.children ?? []).map(child => getSubtreeHeight(child))
      ) + 1

  const tree = getSpendCategoriesTree(spendCategories)
  const subtree = getNodeSubtree(tree, node)

  return subtree ? getSubtreeHeight(subtree) : 0
}

function getEmptySpendCategory (fieldId: string): SpendCategoryNode {
  return {
    node_id: 'null_-1',
    id: -1,
    name: '(None)',
    code: '(None)',
    description: '(None)',
    field_id: fieldId,
    status: SpendCategoryStatusEnum.INACTIVE,
    external_ref: ''
  }
}

function useSpendCategoriesTree (spendCategories: SpendCategory[]): {
  tree: SpendCategoryNode[]
  getPath: (id: number) => Array<number | null>
  getHeight: (categories: SpendCategory[], node: SpendCategoryNode) => number
  getEmptySpendCategory: (fieldId: string) => SpendCategoryNode
} {
  return {
    tree: getSpendCategoriesTree(spendCategories),
    getPath: (id: number) => getPath(spendCategories, id),
    getHeight,
    getEmptySpendCategory
  }
}

export default useSpendCategoriesTree
