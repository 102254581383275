import { type HTMLAttributes, type ReactElement } from 'react'
import {
  ProductStatusEnum,
  ProductStockAvailabilityEnum
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import { format, isTomorrow } from 'date-fns'
import { useTranslation } from 'react-i18next'
import '../assets/scss/ProductStockMessage.scss'

export interface ProductStockMessageProps
  extends HTMLAttributes<HTMLDivElement> {
  availability: ProductStockAvailabilityEnum | null
  estimatedShippingDate?: string | null
  isLoading: boolean
  isSelfServe: boolean
  leadTime?: string | null
  maximumQuantity?: number | null
  minimumQuantity?: number | null
  status: string
}

function ProductStockMessage ({
  className,
  availability,
  estimatedShippingDate,
  isLoading,
  isSelfServe,
  leadTime,
  maximumQuantity,
  minimumQuantity,
  status
}: ProductStockMessageProps): ReactElement {
  const { t } = useTranslation()

  const shippingDateFormat = 'do MMMM Y'

  const inStock =
    !isLoading &&
    availability &&
    availability !== ProductStockAvailabilityEnum.UNAVAILABLE &&
    availability !== ProductStockAvailabilityEnum.NO_STOCK

  const outOfStock =
    !isLoading && availability === ProductStockAvailabilityEnum.NO_STOCK

  const noInfo =
    !isLoading &&
    (!availability || availability === ProductStockAvailabilityEnum.UNAVAILABLE)

  let message = t('product.stock.stock_value.unavailable')
  if (isLoading) {
    message = t('product.stock.stock_value.loading')
  } else if (inStock) {
    switch (availability) {
      case ProductStockAvailabilityEnum.EXACT: {
        message = t('product.stock.stock_value.exact', {
          count: maximumQuantity ?? 0
        })
        break
      }
      case ProductStockAvailabilityEnum.RANGE: {
        message = t('product.stock.stock_value.range', {
          minimum: minimumQuantity,
          maximum: maximumQuantity
        })
        break
      }
      case ProductStockAvailabilityEnum.LESS_THAN: {
        message = t('product.stock.stock_value.less_than', {
          value: maximumQuantity
        })
        break
      }
      case ProductStockAvailabilityEnum.MORE_THAN: {
        message = t('product.stock.stock_value.more_than', {
          value: minimumQuantity
        })
        break
      }
    }
  } else if (outOfStock) {
    message = t('product.stock.stock_value.no_stock')
  }

  if (leadTime) {
    if (/^\d+$/.test(leadTime)) {
      if (parseInt(leadTime) > 0) {
        message += t('product.stock.stock_value.lead_time.days', {
          count: parseInt(leadTime, 10)
        })
      }
    } else if (/^\d+/.test(leadTime)) {
      message += t('product.stock.stock_value.lead_time.leading_number', {
        value: leadTime
      })
    } else {
      message += t('product.stock.stock_value.lead_time', { value: leadTime })
    }
  }

  if (estimatedShippingDate) {
    const date = new Date(estimatedShippingDate)
    message += isTomorrow(date)
      ? t('product.stock.stock_value.estimated_shipping_tomorrow', {
        date: format(date, shippingDateFormat)
      })
      : t('product.stock.stock_value.estimated_shipping', {
        date: format(date, shippingDateFormat)
      })
  }

  return status === ProductStatusEnum.INACTIVE || isSelfServe
    ? (
    <div className={classNames('stock-message', className)}>&nbsp;</div>
      )
    : (
    <div
      className={classNames(
        'stock-message',
        {
          'text-success': inStock,
          'text-warning': noInfo,
          'text-danger': outOfStock
        },
        className
      )}
      title={message}
    >
      {message}
    </div>
      )
}

export default ProductStockMessage
