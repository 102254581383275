import useSWR from 'swr'
import { type OrderLineItems } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseOrderItemsHook {
  data?: OrderLineItems
  estimatedDeliveryDate?: Date
  error?: Error
  isLoading: boolean
}

function useOrderItems (orderId: string): UseOrderItemsHook {
  const { activeAccount } = useAccounts()
  const {
    ordersApi: { getLineItems },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWR<OrderLineItems, Error>(
    orderId && accountId ? ['order-items', orderId, accountId] : null,
    async () => await fetcher(getLineItems, { orderId, accountId, size: 0 })
  )

  const estimatedDeliveryDate = data?.content?.every(
    item => item.estimated_delivery_date
  )
    ? new Date(
      [...data?.content].sort(
        (a, b) =>
          new Date(b.estimated_delivery_date ?? '').getTime() -
            new Date(a.estimated_delivery_date ?? '').getTime()
      )[0]?.estimated_delivery_date ?? 0
    )
    : undefined

  return {
    data,
    estimatedDeliveryDate,
    error,
    isLoading
  }
}

export default useOrderItems
