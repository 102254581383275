import { type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { type OrderRequest } from '@amici/myamici-api-client'
import MaToast from '../../common/components/MaToast'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'

interface OrderRequestCancelModalProps {
  show: boolean
  orderRequest: OrderRequest
  onClose: () => void
}

function OrderRequestCancelModal ({
  show,
  orderRequest,
  onClose
}: Readonly<OrderRequestCancelModalProps>): ReactElement {
  const { t } = useTranslation()
  const { cancel, isCancelling } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToast, closeToast } = useToastNotification()

  const handleClose = (): void => {
    if (isCancelling) {
      return
    }
    onClose()
  }

  const handleCancel = (reason: string): void => {
    void cancel(reason)
      .catch(() => {
        const toastId = Date.now()
        showToast(
          toastId,
          <MaToast
            type="danger"
            onClose={() => {
              closeToast(toastId)
            }}
          >
            <p>
              <Trans i18nKey={'order_request.cancel.error'} />
            </p>
          </MaToast>
        )
      })
      .then(() => {
        void refreshHistory()
      })
      .finally(() => {
        handleClose()
      })
  }

  return (
    <OrderRequestStatusUpdateModal
      show={show}
      onClose={handleClose}
      onConfirm={handleCancel}
      disabled={isCancelling}
      confirmLabel={t('order_request.action.cancel')}
      title={t('order_request.cancel_form.title', {
        ref: orderRequest.order_ref
      })}
      reasonLabel={t('order_request.form.label.cancel_reason')}
      reasonRequired={true}
    />
  )
}

export default OrderRequestCancelModal
