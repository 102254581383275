import { useState } from 'react'
import {
  SpendCategoryStatusEnum,
  type SpendCategory,
  type SpendCategoryField
} from '@amici/myamici-api-client'
import { type SpendCategoryNode } from '../types/spend-category-node'
import useSpendCategories from './useSpendCategories'
import useSpendCategoriesTree from './useSpendCategoriesTree'

interface UseSpendCategoriesPageHook {
  data?: SpendCategoryField[]
  groupNames: string[]
  activeGroupIndex: number
  activeGroup?: SpendCategoryField
  activeSpendCategories: SpendCategory[]
  activeTree?: SpendCategoryNode[]
  showInactive: boolean
  isLoading: boolean
  isUpdatingField: boolean
  isSettingClientDefault: boolean
  spendCategoryFormParams?: {
    parentNode?: SpendCategoryNode | null
    node?: SpendCategoryNode
  }
  handleActiveGroupChange: (value: string) => void
  handleShowInactiveToggle: (value: boolean) => void
  handleAddNew: (parentNode: SpendCategoryNode | null) => void
  handleEdit: (node: SpendCategoryNode) => void
  handleUpdate: (spendCategory: SpendCategory) => Promise<SpendCategory>
  handleUpdateField: (
    spendCategoryField: SpendCategoryField,
  ) => Promise<SpendCategoryField>
  handleSetClientDefault: (
    spendCategory: SpendCategory,
  ) => Promise<SpendCategory>
  handleModalClose: () => void
  getPath: (id: number) => Array<number | null>
  getHeight: (
    spendCategories: SpendCategory[],
    node: SpendCategoryNode,
  ) => number
  getEmptySpendCategory: (fieldId: string) => SpendCategoryNode
}

function useSpendCategoriesPage (): UseSpendCategoriesPageHook {
  const {
    data,
    groupNames,
    handleUpdate,
    handleUpdateField,
    handleSetClientDefault,
    isLoading,
    isUpdatingField,
    isSettingClientDefault
  } = useSpendCategories()

  const [activeGroupIndex, setActiveGroupIndex] = useState<number>(0)
  const [showInactive, setShowInactive] = useState<boolean>(false)
  const [spendCategoryFormParams, setSpendCategoryFormParams] = useState<
  | { parentNode?: SpendCategoryNode | null, node?: SpendCategoryNode }
  | undefined
  >()

  const activeGroup = data?.[activeGroupIndex]

  const activeSpendCategories = (activeGroup?.spend_categories ?? [])
    .filter(spendCategory =>
      showInactive
        ? true
        : spendCategory.status === SpendCategoryStatusEnum.ACTIVE
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  const {
    tree: activeTree,
    getPath,
    getHeight,
    getEmptySpendCategory
  } = useSpendCategoriesTree(activeSpendCategories)

  const handleActiveGroupChange = (value: string): void => {
    setActiveGroupIndex(parseInt(value, 10))
  }

  const handleShowInactiveToggle = (value: boolean): void => {
    setShowInactive(value)
  }

  const handleAddNew = (parentNode: SpendCategoryNode | null): void => {
    setSpendCategoryFormParams({
      parentNode
    })
  }

  const handleEdit = (node: SpendCategoryNode): void => {
    setSpendCategoryFormParams({ parentNode: undefined, node })
  }

  const handleModalClose = (): void => {
    setSpendCategoryFormParams(undefined)
  }

  return {
    data,
    groupNames,
    activeGroupIndex,
    activeGroup,
    activeSpendCategories,
    activeTree,
    isLoading,
    isUpdatingField,
    isSettingClientDefault,
    showInactive,
    spendCategoryFormParams,
    handleActiveGroupChange,
    handleShowInactiveToggle,
    handleModalClose,
    handleAddNew,
    handleEdit,
    handleUpdate,
    handleUpdateField,
    handleSetClientDefault,
    getHeight,
    getPath,
    getEmptySpendCategory
  }
}

export default useSpendCategoriesPage
