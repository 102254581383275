import { type Control, Controller } from 'react-hook-form'
import type { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Col, Form } from 'react-bootstrap'
import styles from '../assets/scss/FormControlTextArea.module.scss'

function FormControlTextArea ({
  label,
  value,
  name,
  required,
  maxLength,
  rows,
  control
}: Readonly<{
  label: string
  value: string
  name: string
  required: boolean
  maxLength: number
  rows: number
  control: Control
}>): ReactElement {
  const { t } = useTranslation()

  return (
    <Form.Group
      as={Col}
      controlId={name}
      className={styles['form-control-text-area']}
    >
      <Form.Label className={required ? 'required' : undefined}>
        {label}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: valueToValidate =>
            valueToValidate.trim().length > maxLength
              ? t('validation.error.form_control_text_area.max_length', {
                count: maxLength
              })
              : true
        }}
        render={({ field, fieldState }) => (
          <>
            <Form.Control
              name={name}
              as="textarea"
              rows={rows}
              required={required}
              value={value}
              onChange={field.onChange}
            />
            <Form.Control.Feedback
              type={fieldState.error ? 'invalid' : 'valid'}
              className={styles.feedback}
            >
              <span>
                <Trans
                  i18nKey="common.form_control_text_area.counter"
                  values={{
                    count: value.trim().length,
                    limit: maxLength
                  }}
                />
              </span>
              {fieldState.error && <span>{fieldState.error.message}</span>}
            </Form.Control.Feedback>
          </>
        )}
      />
    </Form.Group>
  )
}

export default FormControlTextArea
