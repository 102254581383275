import useSWRImmutable from 'swr/immutable'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import {
  type ProductStockAvailabilityEnum,
  type StockCheckResource
} from '@amici/myamici-api-client'

interface UseStockHook {
  availability: ProductStockAvailabilityEnum | null
  minimumQuantity?: number | null
  maximumQuantity?: number | null
  leadTime?: string | null
  estimatedShippingDate?: string | null
  isLoading: boolean
  data?: StockCheckResource
}

function useStock (productId?: string): UseStockHook {
  const { activeAccount } = useAccounts()
  const { stocksApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading }: { data?: StockCheckResource, isLoading: boolean } =
    useSWRImmutable(
      productId && activeAccount?.accountId
        ? ['product-stock', productId, activeAccount?.accountId]
        : null,
      async () =>
        await fetcher(stocksApi.getSupplierProductStockCheck, {
          productId: productId ?? '',
          accountId
        }),
      {
        onErrorRetry: async (
          error,
          key,
          config,
          revalidate,
          { retryCount }
        ) => {
          if (error.response.status === 404) return
          if (retryCount >= 5) return
          await revalidate({ retryCount })
        }
      }
    )

  return {
    availability: data?.availability ?? null,
    minimumQuantity: data?.minimum_quantity,
    maximumQuantity: data?.maximum_quantity,
    leadTime: data?.lead_time,
    estimatedShippingDate: data?.estimated_shipping_date,
    isLoading,
    data
  }
}

export default useStock
