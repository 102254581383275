import useSWRImmutable from 'swr'
import { ProductSearchType } from '../types/product-search-type'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface UseProductFacetsHook {
  isLoading: boolean
  initialFacets: Record<string, Record<string, number>>
  availableFacets: Record<string, Record<string, number>>
}

function useProductFacets (
  term: string,
  type: ProductSearchType,
  facetFilter: string[],
  productId: string = ''
): UseProductFacetsHook {
  const { activeAccount } = useAccounts()
  const { searchApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId

  // Keeping track of the available facets w/o any filters applied
  const { data: initialFacetsData } = useSWRImmutable(
    (term || productId) && accountId
      ? ['initial-product-search-facets', term, productId, type, accountId]
      : null,
    async () => {
      return await fetcher<any>(
        {
          [ProductSearchType.Keyword]: searchApi.getKeywordSearch,
          [ProductSearchType.CASNumber]: searchApi.getCASNumberSearch,
          [ProductSearchType.PartNumber]: searchApi.getPartNumberSearch,
          [ProductSearchType.ECNumber]: searchApi.getECNumberSearch,
          [ProductSearchType.Chemical]: searchApi.getChemicalSearch,
          [ProductSearchType.Biological]: searchApi.getBiologicalSearch,
          [ProductSearchType.Alternatives]: searchApi.getAlternatives
        }[type],
        {
          query: term,
          productId,
          accountId,
          returnFacets: true,
          returnResults: false
        }
      )
    }
  )

  const { data, isLoading } = useSWRImmutable(
    initialFacetsData && (term || productId) && accountId
      ? [
          'available-product-search-facets',
          term,
          productId,
          type,
          facetFilter,
          accountId
        ]
      : null,
    async () =>
      await fetcher<any>(
        {
          [ProductSearchType.Keyword]: searchApi.getKeywordSearch,
          [ProductSearchType.CASNumber]: searchApi.getCASNumberSearch,
          [ProductSearchType.PartNumber]: searchApi.getPartNumberSearch,
          [ProductSearchType.ECNumber]: searchApi.getECNumberSearch,
          [ProductSearchType.Chemical]: searchApi.getChemicalSearch,
          [ProductSearchType.Biological]: searchApi.getBiologicalSearch,
          [ProductSearchType.Alternatives]: searchApi.getAlternatives
        }[type],
        {
          query: term,
          productId,
          accountId,
          ...(facetFilter.length && { facetFilter }),
          returnFacets: true,
          returnResults: false
        }
      )
  )

  return {
    isLoading,
    initialFacets: initialFacetsData?.facets,
    availableFacets: data?.facets
  }
}

export default useProductFacets
