import {
  type LineItemResource,
  type LineItems
} from '@amici/myamici-api-client'
import {
  type ColumnFiltersState,
  type ColumnSort,
  type SortingState,
  type Table
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { addDays, format } from 'date-fns'
import useSWR, { type KeyedMutator } from 'swr'
import ReactGA from 'react-ga4'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useOutstandingOrderItemsTable from './useOutstandingOrderItemsTable'
import { OutstandingOrderItemsColumnId } from '../types/outstanding-order-items-column-id'
import { useSearchParams } from 'react-router-dom'
import { SortDirection } from '../../common/types/sort-direction'
import { type DateRange } from 'react-day-picker'

interface UseOutstandingOrderItemsHook {
  table: Table<LineItemResource>
  filters: ColumnFiltersState
  sorting: SortingState
  totalCount: number
  totalPages: number
  page: number
  size: number
  isLoading: boolean
  mutate: KeyedMutator<LineItems>
  changePage: (page: number) => void
  changePageSize: (size: number) => void
  changeSearch: (search: string) => void
  changeSorting: (sort: ColumnSort | null) => void
  changeDateFilter: (range?: DateRange) => void
}

function useOutstandingOrderItems (
  presetFilter = ''
): UseOutstandingOrderItemsHook {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortBy = searchParams.get('sort')
  const sortingDirection = searchParams.get('direction') ?? SortDirection.ASC
  const search = searchParams.get('search') ?? ''
  const page = parseInt(searchParams.get('page') ?? '1', 10)
  const size = parseInt(searchParams.get('size') ?? '24', 10)
  const dateFrom = searchParams.get('dateFrom') ?? undefined
  const dateTo = searchParams.get('dateTo') ?? undefined

  const dateRangeParams = {
    initial: {
      dateFrom,
      dateTo
    },
    pending: {
      dateFrom: undefined,
      dateTo: undefined
    },
    overdue: {
      dateFrom: undefined,
      dateTo: format(addDays(new Date(), -1), 'yyyy-MM-dd')
    },
    scheduled: {
      dateFrom: format(new Date(), 'yyyy-MM-dd'),
      dateTo: undefined
    },
    due_today: {
      dateFrom: format(new Date(), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd')
    }
  }[presetFilter || 'initial']

  const {
    ordersApi: { getOutstandingLineItems },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId

  const { data, mutate, isLoading } = useSWR<LineItems, Error>(
    accountId
      ? [
          'orders/outstanding-line-items',
          page,
          size,
          sortBy,
          sortingDirection,
          search,
          dateRangeParams?.dateFrom,
          dateRangeParams?.dateTo,
          presetFilter === 'pending',
          accountId
        ]
      : null,
    async () =>
      await fetcher(getOutstandingLineItems, {
        page,
        size,
        sort: sortBy || undefined,
        direction: sortingDirection,
        query: search,
        ...dateRangeParams,
        pending: presetFilter === 'pending',
        accountId
      })
  )

  const lineItems: LineItemResource[] = useMemo(
    () => data?.content ?? [],
    [data]
  )

  const sorting: SortingState = useMemo(
    () => [
      {
        id: sortBy ?? OutstandingOrderItemsColumnId.ESTIMATED_DELIVERY_DATE,
        desc: sortingDirection === SortDirection.DESC
      }
    ],
    [sortBy, sortingDirection]
  )

  const filters: ColumnFiltersState = useMemo(
    () =>
      dateFrom || dateTo
        ? [
            {
              id: OutstandingOrderItemsColumnId.ESTIMATED_DELIVERY_DATE,
              value: {
                from: dateFrom ? new Date(dateFrom) : undefined,
                to: dateTo ? new Date(dateTo) : undefined
              }
            }
          ]
        : [],
    [dateFrom, dateTo]
  )

  const table = useOutstandingOrderItemsTable(
    lineItems,
    data?.totalPages ?? 0,
    {
      columnFilters: filters,
      sorting,
      pagination: {
        pageIndex: page - 1,
        pageSize: 2
      }
    }
  )

  const changePage = (page: number): void => {
    setSearchParams(params => ({
      ...Object.fromEntries(params),
      page: page.toString()
    }))
  }

  const changePageSize = (size: number): void => {
    setSearchParams(params => ({
      ...Object.fromEntries(params),
      size: size.toString(),
      page: '1'
    }))
  }

  const changeSearch = (search: string): void => {
    ReactGA.event('search_item_list', {
      item_list_id: 'outstanding_items',
      search_term: 'outstanding_items:' + search
    })

    setSearchParams(params => {
      params.delete('search')

      return {
        ...Object.fromEntries(params),
        ...(search && { search }),
        page: '1'
      }
    })
  }

  const changeSorting = (sort: ColumnSort | null): void => {
    ReactGA.event('sort_item_list', {
      item_list_id: 'oustandinding_items',
      sort_field: sort?.id,
      sort_order: sort?.desc
    })

    setSearchParams(params => {
      params.delete('sort')
      params.delete('direction')

      return {
        ...Object.fromEntries(params),
        ...(sort && { sort: sort.id }),
        ...(sort && {
          direction: sort.desc ? SortDirection.DESC : SortDirection.ASC
        }),
        page: '1'
      }
    })
  }

  const changeDateFilter = (range?: DateRange): void => {
    setSearchParams(params => {
      params.delete('preset')
      params.delete('dateFrom')
      params.delete('dateTo')

      return {
        ...Object.fromEntries(params),
        ...(range?.from && { dateFrom: format(range.from, 'yyyy-MM-dd') }),
        ...(range?.to && { dateTo: format(range.to, 'yyyy-MM-dd') }),
        page: '1'
      }
    })
  }

  return {
    table,
    filters,
    sorting,
    totalCount: data?.totalElements ?? 0,
    totalPages: data?.totalPages ?? 0,
    page,
    size,
    isLoading,
    mutate,
    changePage,
    changePageSize,
    changeSearch,
    changeSorting,
    changeDateFilter
  }
}

export default useOutstandingOrderItems
