import type { SpendCategory } from '@amici/myamici-api-client'
import {
  type ReactElement,
  type RefObject,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import MaConfirm from '../../common/components/MaConfirm'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'

const FULL_VIEW_MIN_WIDTH_PX = 992

interface BasketGroupEditSpendCategoriesModalProps {
  show: boolean
  onSave: (spendCategories: SpendCategory[]) => Promise<void>
  onCancel: () => void
  disabled: boolean
}

function BasketGroupEditSpendCategoriesModal ({
  show,
  onSave,
  onCancel,
  disabled
}: Readonly<BasketGroupEditSpendCategoriesModalProps>): ReactElement {
  const { t } = useTranslation()
  const [spendCategories, setSpendCategories] = useState<SpendCategory[]>([])
  const ref = useRef<any>(null)
  const compactView = !useMinWidthObserver(
    ref.current?.dialog as RefObject<Element>,
    FULL_VIEW_MIN_WIDTH_PX
  )

  useEffect(() => {
    setSpendCategories([])
  }, [show])

  const onSpendCategoryFieldSelect = (
    newSpendCategory: SpendCategory
  ): void => {
    const updatedSpendCategories = [
      ...spendCategories.filter(
        value => value.field_id !== newSpendCategory.field_id
      )
    ]
    if (newSpendCategory.id !== -1) {
      updatedSpendCategories.push(newSpendCategory)
    }
    setSpendCategories(updatedSpendCategories)
  }

  return (
    <MaConfirm
      ref={ref}
      size="lg"
      title={t('basket.group_edit_spend_categories.title')}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      show={show}
      disabled={disabled || spendCategories.length === 0}
      onConfirm={() => {
        void onSave(spendCategories)
      }}
      onClose={onCancel}
    >
      <p className="small">{t('basket.group_edit_spend_categories.message')}</p>
      <SpendCategoryFieldSelect
        compactView={compactView}
        selectedSpendCategories={spendCategories}
        allowEmpty={true}
        excludeInactive={true}
        onValueChange={onSpendCategoryFieldSelect}
        isBusy={disabled}
      />
    </MaConfirm>
  )
}

export default BasketGroupEditSpendCategoriesModal
