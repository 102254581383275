import { type ReactElement, useRef } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import {
  type OrderRequest,
  OrderRequestBasePriorityEnum
} from '@amici/myamici-api-client'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import MaCard from '../../common/components/MaCard'
import styles from '../assets/scss/OrderRequestCard.module.scss'
import OrderRequestStatusBadge from './OrderRequestStatusBadge'

interface OrderRequestCardProps {
  orderRequest: OrderRequest
  checked?: CheckedState
  onCheckedChange?: (checked: CheckedState) => void
}

const FULL_VIEW_MIN_WIDTH_PX = 480

function OrderRequestCard ({
  orderRequest,
  checked,
  onCheckedChange
}: Readonly<OrderRequestCardProps>): ReactElement {
  const { t } = useTranslation()
  const {
    count,
    total,
    currency,
    lineItems,
    isLoading: isLoadingLineItems
  } = useOrderRequestLineItems(orderRequest?.id)
  const { suppliers, getItemsTotal, getTotalSurcharges } =
    useOrderRequestSummary(lineItems)

  const ref = useRef(null)
  const compactView = !useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  return (
    <MaCard
      ref={ref}
      className={classNames(styles.card, { [styles.compact]: compactView })}
    >
      <MaCard.Header className={styles.header}>
        <MaCheckbox checked={checked} onCheckedChange={onCheckedChange} />

        {orderRequest.order_status && (
          <OrderRequestStatusBadge status={orderRequest.order_status} />
        )}

        <h4>
          <Link to={`/purchasing/order-requests/${orderRequest.id}`}>
            {orderRequest.order_ref}
          </Link>
        </h4>

        {orderRequest.priority &&
          orderRequest.priority !== OrderRequestBasePriorityEnum.NONE && (
            <span
              className={
                styles[`priority-${orderRequest.priority.toLowerCase()}`]
              }
            >
              {t(
                `order_request.priority.${orderRequest.priority.toLowerCase()}`
              )}
            </span>
        )}
      </MaCard.Header>

      <MaCard.Body className={styles.body}>
        {!isLoadingLineItems && (
          <p>
            {orderRequest?.confidential && (
              <span className={styles.confidential}>
                {t('order_request.type.confidential')}
              </span>
            )}
            <Trans
              i18nKey={'order_request.summary.message.requested_by'}
              values={{
                requestedBy: orderRequest.requested_by?.name,
                createdOn: format(
                  new Date(orderRequest.created_date),
                  'do MMMM yyyy'
                )
              }}
            />{' '}
            <Trans
              i18nKey={'order_request.summary.message.items'}
              values={{ count }}
            />{' '}
            <Trans
              i18nKey={'order_request.summary.message.suppliers'}
              values={{
                supplier: suppliers?.[0]?.name,
                count: suppliers.length
              }}
            />{' '}
            <Trans
              i18nKey={'order_request.summary.message.total_cost'}
              values={{ price: total, currency }}
            />
            {orderRequest.required_by_date && (
              <>
                {' '}
                <Trans
                  i18nKey={'order_request.summary.message.required_by_date'}
                  values={{
                    requestedBy: orderRequest.required_by_date,
                    requiredByDate: format(
                      new Date(orderRequest.required_by_date),
                      'do MMMM yyyy'
                    )
                  }}
                />
              </>
            )}
          </p>
        )}
      </MaCard.Body>

      <MaCard.Footer className={styles.footer}>
        <h5>{t('order_request.spend.total')}</h5>

        <div className={styles['spend-summary-entries']}>
          <p>
            <span>
              {t('order_request.spend.items', {
                count
              })}
            </span>
            <strong>
              {t('common.price', { price: getItemsTotal(currency), currency })}
            </strong>
          </p>
          <p>
            <span>{t('order_request.spend.total.est_surcharges')}</span>
            <strong>
              {t('common.price', {
                price: getTotalSurcharges(currency),
                currency
              })}
            </strong>
          </p>
          <p>
            <strong>{t('order_request.spend.total')}</strong>
            <strong>
              {t('common.price', {
                price: total,
                currency
              })}
            </strong>
          </p>
        </div>
      </MaCard.Footer>
    </MaCard>
  )
}

export default OrderRequestCard
