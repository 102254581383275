import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BsGrid, BsList, BsPlus } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import {
  type ViewType,
  ViewTypeValue
} from '../types/order-request-details-summary-view-type'
import MaActionIcon from '../../common/components/MaActionIcon'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import ToggleButtonGroup from '../../common/components/ToggleButtonGroup'
import styles from '../assets/scss/OrderRequest.module.scss'

export interface OrderRequestSpendPanelHeaderControlsProps {
  activeView: ViewType
  compactView: boolean
  allCollapsed?: boolean
  canAdd: boolean
  onExpandAll: () => void
  onCollapseAll: () => void
  onViewChange: (view: ViewType) => void
  onAddNew: () => void
}

function OrderRequestSpendPanelHeaderControls ({
  activeView = ViewTypeValue.grouped,
  compactView,
  allCollapsed,
  canAdd,
  onExpandAll,
  onCollapseAll,
  onViewChange,
  onAddNew
}: OrderRequestSpendPanelHeaderControlsProps): ReactElement {
  const { t } = useTranslation()

  const handleChangeView = (): void => {
    onViewChange(
      activeView === ViewTypeValue.grouped
        ? ViewTypeValue.table
        : ViewTypeValue.grouped
    )
  }

  const handleToggleCollapsed = (): void => {
    allCollapsed ? onExpandAll() : onCollapseAll()
  }

  return compactView
    ? (
    <MaActionMenu>
      {allCollapsed
        ? (
        <MaActionMenuItem onClick={onExpandAll}>
          {t('common.button.labels.expand_all')}
        </MaActionMenuItem>
          )
        : (
        <MaActionMenuItem onClick={onCollapseAll}>
          {t('common.button.labels.collapse_all')}
        </MaActionMenuItem>
          )}
      {canAdd && (
        <MaActionMenuItem onClick={onAddNew}>
          {t('order_request.action.line_item.add')}
        </MaActionMenuItem>
      )}
    </MaActionMenu>
      )
    : (
    <div className={styles.controls}>
      {(compactView || activeView === ViewTypeValue.grouped) && (
        <Button
          variant="outline-primary"
          className={classNames(styles['expand-collapse-btn'], 'rounded')}
          onClick={handleToggleCollapsed}
        >
          {allCollapsed
            ? t('common.button.labels.expand_all')
            : t('common.button.labels.collapse_all')}
        </Button>
      )}

      <ToggleButtonGroup
        onClick={handleChangeView}
        selectedBtnIndex={activeView === ViewTypeValue.grouped ? 0 : 1}
      >
        <BsGrid size={24} title={t('common.view.grouped')} />
        <BsList size={24} title={t('common.view.table')} />
      </ToggleButtonGroup>

      {canAdd && (
        <MaActionIcon
          className={styles['add-btn']}
          title={t('common.button.labels.add_new')}
          onClick={onAddNew}
        >
          <BsPlus size={32} />
        </MaActionIcon>
      )}
    </div>
      )
}

export default OrderRequestSpendPanelHeaderControls
